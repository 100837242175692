/* .article-top{
 margin-top:6rem;
 margin-bottom: 5rem;  
}
.article-card{
    width:338px;
    height:auto;
    display:flex;
    padding:0 6px 6px 6px;
    margin-left: auto;
    margin-right: auto;
    border-radius:15px;
    border: 2px solid lightgrey;
}
.auth-Icon{
    width: 60px;
    height: 60px;  
    }
.author-name{
    font-size: 15px;
    font-weight:600;
    color:black;
}
.follow-author{
    color:#31197C;
    font-size: 15px;
    font-weight:600;
    margin-right:-25px;
    float: right !important;;
}
.heading-art{
    margin-top:10px;
    font-size: 15px;
    font-weight:600;
    color:black; 
}
.img_art{
    width:100%;
    height:150px;
    margin-top:10px;
    margin-left: auto;
    margin-right: auto;
    border-radius:15px;
}
.color-grey{
    color:grey;
    margin-top: 10px;
    font-size:10px;
}
.pub-Date{
     color: grey;
      margin-top: 10px;
      font-size:12px;
      font-weight:600;
} */
.your-jitbits {
    font-size: 16px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 20px;
    margin-bottom: 14px;
}

.latest-art {
    font-size: 16px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 5px;
    margin-bottom: 15px;
}

.top-jit {
    margin-top: -25px;
}

.game-overflow {
    height: 77vh;
    width: 100%;
    margin-top: -0.8rem;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}
.offer-overflow {
    height: 73vh;
    margin-top: -0.1rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.latest-fav-overflow {
    width: 100%;
    margin-top: -0.9rem;
    padding: 0;
}

.fav-overflow {
    height: 49vh;
    width: 100%;
    margin-top: -0.8rem;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.favbits-article-jit {
    width: 100px;
    height: 110px;
    border-radius: 10px;
    background: #f7f7f5;
    border: 1px solid #bebebe;
}

.favbits-title-new {
    margin-top: 2px;
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.jit-position {
    position: absolute;
    bottom: 7px;
}

.jitbits-art-text {
    color: black;
    font-size: 12px;
}

.jitbits-publish {
    font-size: 12px !important;
    color: black;
    margin-right: 6px;
    font-weight: 500;
}

.opp-top {
    margin-top: 18px !important;
    color: black;
    font-size: 13px;
}

/* .jit-top {
    margin-top: 20px !important;
    color: black;
    font-size: 14px;
} */

.opp-img-Author {
    margin-top: 4px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-left: 5px;
}

.view_opp_card {
    height: 100px;
    width: 100%;
    margin-bottom: -10px;
    background: #fffffb;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}
.view_eve_card {
    height: auto;
    width: 100%;
    margin-bottom: -10px;
    background: #f1f4f8;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}
.jitbits-img-Author-art {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-left: 5px;
}

.jitbits-img-topicIcon {
    width: 25px;
    height: 26px;
    margin-top: 18px;
    margin-left: 95%;
}

.jitbits--card {
    height: 158px;
    width: 100%;
    margin-bottom: -15px;
    background: #fffffb;
    border-radius: 7px;
    padding: 4px;
    color: black;
    border: 1px solid #31197C;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.registered-img-Author {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 8px;
}

.user-desc-title-new {
    margin-top: -6px;
    font-size: 15px;
    font-weight: 500;
    color: black;
}

.register-name {
    font-size: 13px;
    font-weight: 600;
}

.registeredUser {
    font-size: 10px;
    color: black;
    font-weight: 600;
    text-align: center;
}

.registeredUser-plan {
    font-size: 12px;
    color: black;
}

.registeredUser-interest {
    font-size: 14px;
    color: black;
    font-style: italic;
}

.registered-users-overflow {
    height: 12vh;
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.registeredUser-role {
    font-size: 12px;
    color: black;
}

.registeredUser-referred {
    font-size: 12px;
    color: black;
    font-style: italic;
}
.background-gradient {
    background: linear-gradient(to bottom, #FBE3D6, #FFFFFF, #DCEAF7);
    border-radius: 20px;
    margin-top: -20px;
  }
.games_card {
    height: 110px;
    width: 99%;
    margin-bottom: -10px;
    background: #fffffb;
    border-radius: 7px;
    border: none;
    border-left:1px solid lightgray;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;

}

.games-img {
    width: 84px;
    /* border-radius: 10px;
    background: #f7f7f5;
    border: 1px solid #bebebe; */
}

.games-img_quiz {
    width: 80%;
}

.games-img_seq {
    width: 95px;
}

.g-image {
    width: 97px;
    margin-left: 5px;
    height: 93px;
    border-radius: 8px;
    background: #f7f7f5;
    border: 1px solid #bebebe;
}

.game-Text {
    font-size: 14px;
    color: black;
}

.game-rewards {
    font-size: 14px;
    color: black;
    font-weight: 600;
}

.game-image {
    position: relative;
    top: 3rem;
    width: 125px;
}

.game-s-image {
    position: relative;
    top: 3rem;
    width: 100px;
    height: 100px;
}

.game-text {
    margin-top: 4rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #2B2C43;
}

.game-seq-text {
    margin-top: 3.5rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
    color: #2B2C43;
}

.game-boxes {
    /* position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom:10rem;
    text-align: center; */
    bottom: 9.6rem;
    margin: auto;
    position: absolute;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
}

.game-boxes-sequence {
    bottom: 9rem;
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.newly-registered {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.game_boxes {
    /* position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom:10rem;
    text-align: center; */
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}