input[type="checkbox"].switch1  {
	position: sticky;
	opacity: 0;
}
input[type="checkbox"].switch1 + div {
	vertical-align: middle;
	width: 44px;
	height: 28px;
	border-radius: 12px;
	background-color: lightgrey;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-property: background-color, box-shadow;
	transition-property: background-color, box-shadow;
	cursor: pointer;
}

input[type="checkbox"].switch1 + div span {
	position: sticky;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}
 input[type="checkbox"].switch1 + div span:nth-child( 1 ) {
    margin-left: 15px;
}

input[type="checkbox"].switch1 + div span:nth-child( 2 ) {
    margin-left: 55px;
} 

input[type="checkbox"].switch1:checked + div {
	width: 44px;
	background-position: 0 0;
	background-color: lightgrey;
}

input[type="checkbox"].switch1 + div {
	width: 44px;
	height: 28px;
}
input[type="checkbox"].switch1:checked + div {
	background-color:  #FF942C;
}
input[type="checkbox"].switch1 + div > div {
	float: left;
	width: 16px; height: 16px;
	border-radius: inherit;
	background: #ffffff;
	-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
	transition-timing-function: cubic-bezier(1,0,0,1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, background-color;
	transition-property: transform, background-color;
	pointer-events: none;
	margin-top: 6px;
	margin-left: 4px;
}
input[type="checkbox"].switch1:checked + div > div {
	-webkit-transform: translate3d(10px, 0, 0);
	transform: translate3d(10px, 0, 0);
	background-color: #ffffff;
}
input[type="checkbox"].switch1:checked + div > div {
	-webkit-transform: translate3d(20px, 0, 0);
	transform: translate3d(20px, 0, 0);
}
.notification-heading{
    margin-top:7rem;
    margin-left: 1.6rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #1E2022;
    }
.notifications-sm{
    margin-top:1rem;
    margin-left: 1.6rem;
    margin-right: 0.5rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15.5px;
    line-height: 20px;
    color: #000000;
    }
.float-right{
    float:right;
    margin-right:1.6rem;
    margin-top:-1.2rem;  
}
.label-text{
    margin-left:1.6rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 25px;
    color: #000000;
}