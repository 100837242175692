/* .side-nav {
    height: 25%;
    width: 0;
    position: fixed;
    z-index: 10;
    top: 43%;
    left: 0;
    border-radius: 15px;
    background-color: #31197C;
    opacity: 0.9;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.side-nav-text {
    color: white !important;
    text-decoration: none !important;
}

.side-nav a:hover {
    color: white !important;
}

.side-nav .close-btn {
    position: fixed;
    top: 42.5%;
    left: 42.5%;
    font-size: 36px;
    color: white;
} */

.rectangle {
    position: relative;
    top: 0rem;
    float: right !important;
    width: 50px;
    height: 58px;
    /* margin-top: -1.5rem; */
}

.landing-Image {
    position: fixed;
    left: 7.2%;
    right: 37.87%;
    top: 11.69%;
    bottom: 73.4%;
    width: 8%;
}

.landing-text {
    position: absolute;
    left: 7.2%;
    right: 37.87%;
    top: 11.69%;
    bottom: 73.4%;
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 48px;
    color: #2B2C43;
}

.choose-text {
    margin-left: 1.6rem;
    margin-top: 22vw;
    /* right: 37.87%;
    top: 15.69%;
    bottom: 73.4%; */
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #FF942C;
}

.auth-icon1 {
    width: 50px;
    height: 52px;
    border-radius: 50%;
    margin-top: 2px;
    margin-bottom: 2px;
}

.auth-icon {
    width: 50px;
    height: 50px;
    /* width: 100%;
    height: 100%; */
    border-radius: 50%;
    margin-bottom: 6px;
}

.auth-dot {
    height: 55px;
    width: 55px;
    border: 3px solid #31197C;
    background-color: white;
    border-radius: 50%;
    margin-left: -2px;
}

.auth-dot-none {
    height: 55px;
    width: 55px;
    border: 3px solid transparent;
    background-color: white;
    border-radius: 50%;
    margin-left: -2px;
}

.procurement {
    width: 32px;
    height: 32px;
}

.logistics {
    width: 62px;
    height: 43px;
}

.assets {
    width: 59px;
    height: 59px;
}

.text-topic {
    margin-top: 4px;
    font-family: Noto Sans;
    font-size: 11px;
    line-height: 13px;
    color: #000000;
    text-align: center;
    justify-content: center;
}

.text {
    margin-top: 4px;
    font-family: Noto Sans;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    text-align: center;
    justify-content: center;
}

.text-userN {
    margin-top: 2px;
    font-family: Noto Sans;
    font-size: 12px;
    white-space: nowrap;
    line-height: 14px;
    color: #000000;
    text-align: center;
    justify-content: center;

}

.border_ {
    border: 1px solid lightgrey;
}

.text-authorname {
    margin-top: 2px;
    font-family: Noto Sans;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    white-space: nowrap;
    align-items: center;
    text-align: center;
    /* margin-left: 4px;
    margin-right: 8px; */
}

.term-Text_box {
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.term-Text {
    font-family: Noto Sans;
    font-size: 25px;
    font-weight: 600;
    color: #000000;
    margin-bottom: -4px !important;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.topics-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid white;
    margin: 10px;
    padding-bottom: 2px;
}

.uppercase-bold {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    font-family: Noto Sans;
}

.homeArticleText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    font-family: Noto Sans;
    font-size: 10px;
}

.art-topic-auth-text {
    color: black;
    font-family: Noto Sans;
    font-size: 10px;
}

.points-text {
    color: black;
    font-family: Noto Sans;
    font-size: 16px;
    margin-top: -3px;
}

.auth-center {
    display: flex;
    justify-content: start;
}

.topicLeft {
    display: flex;
    justify-content: start;
    width: 24px;
}

.adv-text-details {
    height: 25vh;
    overflow-y: auto;
}

.articleCard-height {
    height: 42vh;
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.articleCard {
    border: none;
    height: max-content;
    /* height:5.5rem; */
    border-radius: 12px;
    padding: 0;
    background: #F2F2FE;
    margin: 6px 12px 8px 12px;
    box-shadow: 2px 2px 4px #bebebe;
}

.articleCard-height-empty {
    height: 34vh;
    width: 100%;
    padding: 14vh 1rem;
    display: flex;
    text-align: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
}

.titleText-favbits {
    color: black;
    font-family: Noto Sans;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.titleText {
    color: black;
    font-family: Noto Sans;
    font-size: 13px;
    margin-left: -8px;
    margin-top: -3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.titleText-event {
    color: black;
    font-family: Noto Sans;
    font-size: 13px;
    margin-left: -8px;
    margin-top: -1px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.stickytable {
    position: sticky;
    top: 11.7rem;
    z-index: 1000;
}

.table-of-contents-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 91.5%;
    background-color: #fcecdd;
    padding: 7px;
    border-radius: 5px;
    font-size: 18px;
    font-family: Raleway, sans-serif !important;
    margin-left: 4%;
    margin-right: 4%;
}

.angle-size {
    font-size: 24px;
    color: #ff942C;
    cursor: pointer;
}

.dd-content {
    font-family: Raleway, sans-serif !important;
    background-color: white;
    padding: 5px 2px 1px 2px;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 91%;
    margin-top: -0.2rem;
}

.dd-content ol {
    list-style-type: decimal;
    margin-left: 1.3rem;
    padding: 2px;
    font-weight: 500;
    color: black;
    font-size: 14px;
    font-family: Raleway, sans-serif !important;
}

.table-of-contents ul {
    list-style-type: disc;
}

/* .term-Text-t {
    font-family: Noto Sans;
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
} */


.term-Text-white {
    margin-top: 2px;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    color: white;
    align-items: center;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
}

._hr {
    width: 80%;
    height: 0.3px;
    background-color: grey;
    opacity: 0.3;
    margin-top: -4px;
}

.landing-text-sm {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #2B2C43;
    opacity: 0.56;
    margin-left: 1.6rem;
}

.landing-btn {
    width: 110px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.landing-btn:hover {
    width: 110px;
    height: 54px;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.float {
    float: right;
    margin-right: 1.6rem;
}

.vl {
    border-left: 1px solid grey;
    height: 104vh;
    position: absolute;
    left: 33.33%;
    top: 26%;
    opacity: 0.4;
    width: 0.6px;
    background-color: grey;
}

.vl-1 {
    border-left: 1px solid grey;
    width: 0.6px;
    background-color: grey;
    height: 104vh;
    position: absolute;
    left: 66.66%;
    top: 26%;
    opacity: 0.4;
}

.follow-text {
    margin-left: 1.6rem;
    margin-top: 6rem;
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #FF942C;
}

.overlay {
    display: flex;
}

.hide-checkbox {
    visibility: hidden;
}

.display-checkbox {
    visibility: visible;
}

.switch {
    width: 120px;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #FF942C;
}

.switch-d {
    width: 120px;
    height: 40px;
    border-radius: 3px;
    cursor: pointer;
    border: 2px solid #FF942C;
}

/* .switch:after {
    position: absolute;
    top: 5%;
    width: 45%;
    height: 90%;
    background: #fff;
    transition: all 0.3s ease-in 0s;
    border: 1px solid rgba(241, 172, 23, 0);
    border-radius: 3px;
  } */


/* .bottom-bar {
position: fixed;
width: 375px;
height: 71px;
left: 0px;
bottom: 0;
background: #FFFFFF;
  } */

.home-top {
    margin-top: 4.5rem;
    margin-left: 1.6rem;
}

.explore-text {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.auth-tl {
    margin-left: 0px;
}

.scroll-authors {
    width: 66px;
    height: 62px;
    display: flex;
}

.scroll-interests {
    width: 8rem;
    height: 0.5rem;
    display: flex;
    padding-top: 6px;
    margin-left: 3px;
}

.interests-main {
    width: 100%;
    height: 80px;
}

.scroll_interests {
    width: auto;
    height: 80px;
    display: flex;
    margin-left: -5px;
}

.your-interests {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.8px;
    color: #1E2022;
    margin-left: 1.7rem;
    margin-top: 4px;
}

.outline-btn {
    margin-top: 7px;
    margin-right: 20px;
}

.btn-outline-sm {
    border: 2px solid whitesmoke;
    background: none;
    width: auto;
    height: 2.2rem;
    padding: auto;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    white-space: nowrap;
    text-align: center;
}

.btn-outline-sm:hover {
    outline: 2px solid #FF942C;
    box-shadow: none;
}

.btn-outline-sm-all {
    border: 2px solid whitesmoke;
    background: none;
    width: auto;
    height: 2.2rem;
    margin-left: -8px;
    margin-right: -8px;
    padding: auto;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    white-space: nowrap;
    text-align: center;
}

.btn-outline-sm-all:hover {
    outline: 2px solid #FF942C;
    box-shadow: none;
}

.btn-outline-term {
    border: 1px solid rgba(48, 25, 124, 0.3);
    background: #f9f9f9;
    width: auto;
    height: 2rem;
    padding: auto;
    font-size: 14px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
    white-space: nowrap;
    text-align: center;
}

.left {
    margin: 0px;
}

.btn-icon {
    float: left;
    width: 25px;
}

.int-text {
    font-family: Noto Sans;
    font-size: 13px;
    color: #000000;
}

.new-text {
    font-family: Noto Sans;
    font-size: 13px;
    color: #000000;
}

.new-text-white {
    font-family: Noto Sans;
    font-size: 13px;
    color: white;
}

.latest-news {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.8px;
    color: #1E2022;
    margin-left: 1.6rem;
    margin-top: 34px;
}

/* .main {
    margin-top: 5vh;
    width: 100vw;
    height: 15vh;
} */

/* .interests_main {
    margin-top: 10px;
    width: 100%;
    height: 15vh;
} */

/* .article-main {
    width: 100%;
    height: 34vh;
} */
.article-main {
    width: 100%;
    height: auto;
    margin-top: -10px;
    /* background-color: rgb(235, 231, 231); */
}

.interests_main {
    margin-top: -15px;
    width: 100%;
    height: 68px;
}

.New_Members {
    width: auto;
    height: 0px;
    display: flex;
    margin-top: -5px;
}

.new-members-main {
    margin-top: -10px;
    width: 100%;
    height: 145px;
}

.new-members-sm-all {
    border: none;
    background: linear-gradient(to top bottom, #f7d9fa, #c7e3f0);
    width: 30vw;
    height: 30vw;
    padding: 4px;
    border-radius: 15px;
    color: white;
}

.new-members-sm-all-rewards {
    margin: 3px 2px;
    border: 2px solid whitesmoke;
    background: linear-gradient(to top bottom, #c7e3f0, #f7d9fa);
    /* width: 120px;
    height: 114px; */
    /* width: 30.5vw;
    height: 29vw; */
    width: 18vw;
    height: 13vw;
    padding: 5px;
    border-radius: 15px;
}

.new-members-sm-all-games {
    /* margin-top:-5px; */
    margin: 3px 2px;
    border: 2px solid whitesmoke;
    background: linear-gradient(to top bottom, #c7e3f0, #f7d9fa);
    /* width: 30.5vw;
    height: 29vw; */
    width: 18vw;
    height: 13vw;
    /* width:120px;
    height: 114px; */
    /* width: 32.5vw;
    height: 29vw; */
    padding: 5px;
    /* position: absolute;
    left:37%; */
    border-radius: 15px;
}

/* .new-members-sm_all_ {
    border: 2px solid whitesmoke;
    background: linear-gradient(to top bottom, #c7e3f0, #f7d9fa);
    width: 38.5vw;
    height: 13vw;
    border-radius: 15px;
}
.term-Text-t {
    font-family: Noto Sans;
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    text-align: center;
} */
.new-members-sm_all_ {
    border: 2px solid whitesmoke;
    background: linear-gradient(to top bottom, #c7e3f0, #f7d9fa);
    width: 38.5vw;
    height: 13vw;
    border-radius: 15px;
    display: flex;
    align-items: center;
    /* Center contents vertically */
}

.fixed-image-col {
    position: absolute;
    /* Fixed positioning */
    left: 7px;
    /* Adjust as needed */
    top: 50%;
    /* Center vertically */
    transform: translateY(-72%);
    /* Centering trick */
}

.term-Text-t {
    font-family: Noto Sans;
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    text-align: left;
    /* Align text to the left */
    display: flex;
    /* Flex container */
    align-items: center;
    /* Center text vertically */
    justify-content: flex-start;
    /* Align text to the left */
    padding-left: 40px;
    /* Padding to ensure space from the image */
    height: 100%;
    /* Full height to align text properly */
}

.box1 {
    /* width: 38.5vw; */
    width: 55vw;
    height: 13vw;
    border-radius: 15px;
    border: 2px solid whitesmoke;
    background: linear-gradient(to top bottom, #c7e3f0, #f7d9fa);
    display: flex;
    font-family: Noto Sans;
    align-items: center;
    margin-left:2px;
    /* Center items vertically */
    position: relative;
    /* Allow absolute positioning within this box */
    padding: 0 2px;
    /* Add some padding for text */
}

.box1 img {
    position: absolute;
    left: 4px;
    /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    /* Center vertically */
    height: 20px;
    /* Adjust image size */
}

.text1 {
    flex: 1;
    /* Take remaining space */
    margin-left: 30px;
    /* Space for the image */
    font-size: 13px;
    font-weight: 600;
    font-family: Noto Sans;
    display: flex;
    /* Use flexbox */
    align-items: center;
    /* Center text vertically */
}

.text1 p {
    margin: 0;
    /* Remove default margin */
}

.new-members-sm_all_term {
    width: 38.5vw;
    height: 14vw;
    border: 1px solid gray;
    border-radius: 15px;
    margin: 4px 2px;
    background: linear-gradient(to top bottom, #c7e3f0, #f7d9fa);
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-members-sm_all_term img {
    width: 25px;
    padding: 0 3px;
    margin-right: 8px;
    /* Adjust margin as needed */
}

.term-Text-tt {
    font-family: 'Noto Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    text-align: center;
}

.linktt {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    /* Center content vertically */
}

.new-members-img-bg {

    padding-left: 4px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 2px;
    border-radius: 10px;
    width: 30%;
    height: 30%;
}

.new-members-img-bg- {
    padding-left: 4px;
    padding-right: 18px;
    padding-bottom: 5px;
    padding-top: 2px;
    border-radius: 10px;
    width: 30%;
    height: 30%;
}

.new-members-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 30vw);
    column-gap: 15px;
    margin-top: -10px;
}

.main {
    width: 100vw;
    height: 95px;
}

.ads-main {
    width: 100%;
    height: 15vh;
    /* background-color: blue; */
}

.scroll-articles-box {
    width: 88vw;
    /* height: 30vh; */
    height: 24vh;
    display: flex;
    padding: 12.8vh 23vw;
    border-radius: 10px;
    font-size: 16px;
    margin-bottom: 15px;
    border: 1px solid rgba(48, 25, 124, 0.3);
    /* height: auto;
    width:158px; */
}

.scroll-articles {
    width: 38vw;
    /* height: 31vh; */
    height: 26vh;
    /* height:220px; */
    display: flex;
    padding: 0 4px 4px 4px;
    margin-right: 10px;
    /* height: auto;
    width:158px; */
}

/* .articles-Alignment{
    margin-left: 5px;
    margin-right: 5px;
} */
.posts-card {
    margin-top: -2px;
    border-radius: 10px;
    margin-left: 10px;
    background: none;
    /* height: 29vh; */
    height: 23.5vh;
    width: 39vw;
    /* height: 210px; 
    width: 154px;*/
    margin-bottom: 10px;
    border: 2px solid whitesmoke;
    /* box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff; */
}

.article-img {
    /* margin-top: 13px;
    margin-left: 13px;
    width: 125px;
    height: 120px; */
    margin-top: 1vh;
    margin-left: 2.3vw;
    width: 34vw;
    height: 16.5vh;
    background: none;
    border-radius: 10px;
    /* border: 1px solid #bebebe; */
}

.opp-fs {
    font-size: 12px;
    height: 2.4rem;
    padding: auto 2px;
}

.opp-date {
    margin-top: -6px;
    height: 2.4rem;
    font-size: 12px;
    width: 100%;
}

.opp-date:hover {
    margin-top: -6px;
    height: 2.4rem;
    font-size: 12px;
    width: 100%;
}

.form-drop {
    width: 100%;
    height: 2.1rem;
    font-size: 14px;
    margin-top: -2px;
    padding-top: -2px;
}

.article-heading {
    font-family: Noto Sans;
    font-style: normal;
    font-size: 12.5px;
    line-height: 16px;
    color: black;
    width: 39vw;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
}

.img-Author {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 2vw;
    position: absolute;
    bottom: 1.5vh;
}

.img-topicIcon {
    width: 30px;
    height: 31px;
    margin-left: 30.5vw;
    position: absolute;
    bottom: 1.5vh;
}

.mb-bottom {
    margin-bottom: 4.5rem;
}

.back {
    position: absolute;
    top: 7%;
    margin-left: 1.6rem;
    width: 8%;
}

._heading {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
    color: #FFFFFF;
    position: absolute;
    top: 40.5%;
    margin-left: 1.4rem;
}

.tagline {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
    margin-left: 1.7rem;
}

.heading-color {
    color: #31197C;
    font-family: Raleway, sans-serif;
}

.term-f-scroll {
    height: 50vh;
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.tagline-title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FF942C;
    margin-top: 10px !important;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    text-align: left;
}

.tag-title {
    width: 100%;
    background: white;
    position: fixed;
    top: -7px;
    z-index: 10;
    /* border-bottom: 1px solid #31197C; */
}

.user-Name {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: black;
}

.rewards-earned {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: black;
}

.to-know-more {
    font-size: 13px;
}

.accordion-header {
    border-radius: 15px !important;
}

.accordion-body {
    border-radius: 15px !important;
    background-color: #eae3f8;
}

.click {
    color: #FF942C;
    font-size: 15px;
    cursor: pointer;
}

.author-icon {
    width: 40px;
    margin-left: 2.5rem;
}

.authorName {
    margin-left: 0.5rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: black;
    margin-top: 12px;
}

.Author_icon {
    width: 40px;
    margin-left: 1.5rem;
}

.author-Name {
    margin-left: 1.8rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: black;
    margin-top: 5px;
}

.pubDate {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: black;
    margin-left: 0.5rem;
}

.pubDate-new {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: black;
    margin-left: 2.7rem;
}

.icons-sm {
    position: fixed;
    right: 0.4%;
    top: 34%;
    width: 23px;
    background-color: rgb(231, 228, 228);
    align-items: center;
    justify-content: center;
}

.mt-icon-top {
    margin-top: 15px !important;
}

.mt-icon-top-b {
    margin-top: 15px !important;
    margin-bottom: 5px !important;
}

.Article_Image_width {
    width: 100%;
    height: 350px;
}

/* .author-icon{
    position:absolute;
    top:36%;
    width:40px;
    margin-left:1.6rem;
    }
.authorName{
    position:absolute;
    top:36.5%;
    margin-left:4.5rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    }
.pubDate{
font-family: Noto Sans;
font-style: normal;
font-weight: normal;
font-size: 11px;
line-height: 16px;
color: #FFFFFF;
position:absolute;
top:39%;
margin-left:4.5rem;
}
.icons-sm{
    position:absolute;
    top:37%;
    right:1%;
} */
._share {
    width: 12%;
    margin-left: 5rem;
}

._heart {
    width: 12%;
    margin-left: 1rem;
    margin-right: 1rem;
}

._bookmark {
    width: 8%;
    margin-right: -1.7rem;
}

.html-text {
    font-family: Noto Sans;
    font-style: normal;
    font-size: 11px;
    color: #000000;
    margin-left: 1.8rem;
    margin-right: 1rem;
    margin-top: 0.2rem;
}
.custom-modal .modal-dialog {
    position: fixed;
    bottom: 4rem;  /* Position the modal 4 rem from the bottom */
    left: 3%;
    right:5%;
    width:90%;
    z-index: 1040;
  }
.html-texT {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
    margin-left: 1.2rem;
    text-align: left;
    display: inline-block;
    text-align: left;
    margin-right: 1.2rem;
    margin-top: 0.2rem;
}
.html-texT.p-type {
    max-height: calc(20px * 14);
    overflow: hidden;
    position: relative;
}

.html-texT.p-type:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px; /* Height of the fade effect */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); /* Fading effect */
}

/* For "G" type, no limit, show content normally */
.html-texT.g-type {
    display: block;
}
.read-also {
    font-size: 16px;
    font-family: Noto Sans;
    color: #311942;
    font-weight: 600;
    border: 3px solid #ff942c;
    padding: 5px 10px;
    border-radius: 20px;
}

.hide-scroll {
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.follow-btn {
    width: 9rem;
    height: 42px;
    color: white;
    border-radius: 30px;
    background: #FF942C;
}

.follow-btn:hover {
    width: 9rem;
    height: 42px;
    color: white;
    border-radius: 30px;
    background: #FF942C;
}

.author-text {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.54)
}

.card-width-sm {
    background: #F6F6F6;
    height: 3.5rem;
    padding: 3px;
    box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.03);
    border-radius: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.numbers {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #31197C;
    margin-bottom: 2px;
}

.card-width {
    background: #F6F6F6;
    box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.03);
    border-radius: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.posts {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #31197C;
}

.-float {
    float: right !important;
}

.h-length {
    width: 100%;
    height: 0.2px;
    background-color: grey;
    opacity: 0.4;
    margin-top: 3px;
}

.oops {
    margin-top: -1.8rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.8px;
    color: #352C78;
}

.notfound {
    margin-top: -10px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.below-notfound {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.8px;
    color: #000000;
    margin: -10px 3rem auto 3rem;
}

.adv-img {
    width: 190px;
    height: 140px;
    border-radius: 10px;
    /* background:linear-gradient(135deg, rgba(0,0,0,0.22), rgba(255,255,255,0.25)); */
}

/* .adv-image_ {
    width: 100px;
    height: 122px;
    margin-top: -3.8px;
    margin-left: -1px;
    border-radius: 10px;
    border: none;
    background: #ffd464;
} */
/* .zigzag {
    position: relative;
    padding: 8px 8px 32px 8px;
    background: #dddccf;
} */
div hr.zig,
div hr.zag {
    border: none;
    height: 30px;
    margin: 0 50px;
}

div hr.zig {
    background: linear-gradient(-135deg, #FFF 20px, rgba(0, 0, 0, 0) 0) 0 5px, linear-gradient(135deg, #FFF 20px, rgba(0, 0, 0, 0) 0) 0 5px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 20px 40px;
    position: relative;
    transform: rotate(90deg);
}

div hr.zag {
    background: linear-gradient(-135deg, #31197C 20px, rgba(0, 0, 0, 0) 0) 0 5px, linear-gradient(135deg, #31197c 20px, #FFF 0) 0 5px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 20px 40px;
    margin-top: -28px;
    transform: rotate(90deg);
}

.zigzag {
    background: linear-gradient(-45deg, black 7px, transparent 0), linear-gradient(45deg, black 7px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 14px 14px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    transform: rotate(90deg);
    left: 0px;
    width: 100%;
    height: 100%;
}

.adv-image_ {
    /* width: 60px;
    height: 88px;
    float: right;
    margin-top: -3.8px;
    margin-left: -1px;
    border-radius: 10px;
    border: none; */
    /* background: #ffd464; */
    /* display: flex;
    width: 30%;
    align-items: center;
    justify-content: center;
    text-align: center; */
    /* margin-top: 25px;
    margin-left: 15px; */
    width: 30%;
    margin-top: 16%;
    margin-bottom: 16%;
    margin-left: 15px;
    align-items: center;
}

.camp-name {
    /* color: black;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-left: 16px;
    float: left;
    margin-top: auto;
    font-size: 16px;
    margin-bottom: auto; */
    color: black;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-left: 16px;
    font-size: 16px;
    display: flex;
    align-items: center;
    float: left;
    margin-top: auto;
    margin-bottom: auto;
}

.link {
    text-decoration: none !important;
}

/* .camp-col {
    color: white;
    font-size: 14px;
    float: right;
    margin-top: 25px;
    margin-left:3rem;
} */
.camp-col {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    color: white;
    font-size: 14px;
}

.adv-image-home {
    max-width: 80px;
    padding: 3px;
    margin-top: -10px;
}
.event-image-home {
    max-width: 58px;
    padding: 3px;
    margin-top: -7px;
}
.form-size {
    font-size: 12px;
    font-family: Noto Sans;
}

.camp-name-home {
    margin-right: 20px;
    margin: 3px 5px;
    font-weight: 500;
    font-family: Noto Sans;
    color: black;
    font-size: 13px;
}

.camp-name-home-event {
    margin-right: 20px;
    margin: 1px 5px;
    font-size: 13px;
    font-weight: 500;
    font-family: Noto Sans;
    color: black;
}

.camp-sme {
    color: white;
    font-size: 14px;
    float: right !important;
    position: absolute;
    right: 2rem;
}

._rolebtn_new {
    background-color: #31197C;
    width: 5rem;
    height: 32px;
    border-color: #31197C;
    border-radius: 4px;
    text-align: center;
    margin: auto;
    padding-top: 5px;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

._rolebtn_new:hover {
    background-color: #31197C;
    border-color: #31197C;
    color: white;
}

._rolebtn {
    background-color: #31197C;
    width: 4.5rem;
    height: 25px;
    border-color: #31197C;
    border-radius: 4px;
    text-align: center;
    margin: auto;
    padding-top: 2px;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

._rolebtn:hover {
    background-color: #31197C;
    border-color: #31197C;
    color: white;
}

.scroll-adv {
    width: 340px;
    height: 140px;
    display: flex;
    padding: 6px;
    margin-right: 10px;
}

.padding {
    padding: 10px;
    border-style: dashed;
    border-radius: 10px;
    width: 97%;
    margin: 5px;
    color: white;
    height: 110px;
}

.padding-top {
    margin-top: -5px;
}

.padding-inner {
    border-style: dashed;
    border-top: none;
    border-bottom: none;
    border-right: none;
    width: 97%;
    color: white;
    margin-top: -15px;
    height: 105px;
    border-width: 5px;
}

.img-padding {
    padding: 10px;
    border-style: dashed;
    border-radius: 10px;
    width: 100%;
    margin: 5px;
    color: white;
    height: auto;
}

.border-adv {
    border: none;
    border-radius: 16px;
    width: 92%;
    height: 120px;
    background: skyblue;

}

.b-adv_card {
    border: none;
    border-radius: 16px;
    width: 92%;
    height: 120px;
    background: skyblue;
    border: none;
}

/* #carousel1 .carousel-indicators li {
    width: 8px;
    height: 8px;
    bottom: 4rem;
    border-radius: 100%;
    background-color: #31197C;
    z-index: -100;
    position: absolute;
    width: 100%;
    display: flex;
} */


.carousel-indicators li {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #31197C;
    z-index: -100;
    position: relative;
    top: 1.3rem;
    display: none;
}

.custom-col {
    border: 2px solid black;
}

.adv-main {
    width: 100%;
    height: 180px;
}

.richTextFieldreview {
    position: relative;
    width: 90%;
    height: 25vh;
    left: 20px;
    margin-top: 0.5rem;
    text-align: left;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: #ececec;
    border-radius: 6px;
    z-index: 0;
    font-size: 14px;
    overflow: auto;
}

.richTextFieldreviewSme {
    position: relative;
    width: 98%;
    height: 52vh;
    left: 8px;
    margin-top: 0.5rem;
    text-align: left;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: #ececec;
    border-radius: 6px;
    z-index: 0;
    font-size: 14px;
    overflow: auto;
}

.richTextFieldrefer {
    position: relative;
    width: 90%;
    height: 16vh;
    left: 20px;
    margin-top: 0.5rem;
    text-align: left;
    padding-left: 14px;
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: #ffffff;
    border-radius: 6px;
    z-index: 0;
    font-size: 14px;
    overflow: auto;
}

.role-position {
    position: absolute;
    top: 18.5rem;
}

.role-position-new {
    position: absolute;
    top: 17.5rem;
    width: 100%;
}

.full-adv {
    width: 50%;
    height: 150px;
    margin-top: 3rem !important;
    border-radius: 10px;
}

/* .bg_event {
    background: hsla(186, 33%, 94%, 1);
    background: linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%);
    background: -moz-linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#EBF4F5", endColorstr="#B5C6E0", GradientType=1);
    min-height: 85dvh; 
} */
.bg_event {
    color: white;
    background: #31197C;
    background: linear-gradient(90deg, #745eb5 0%, #31197C 100%);
    /* Gradient from dark to lighter color */
    background: -moz-linear-gradient(90deg, #745eb5 0%, #31197C 100%);
    /* For Firefox */
    background: -webkit-linear-gradient(90deg, #745eb5 0%, #31197C 100%);
    /* For Safari */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#745eb5", endColorstr="#31197C", GradientType=1);
    /* For IE */
    min-height: 85dvh;
    /* Minimum height */
}

/*
 .bg_event1 {
    background: #022a64;
    background: linear-gradient(90deg, #022a64 0%, #07466e 33%, #095273 66%, #07486f 100%);
    background: -moz-linear-gradient(90deg, #022a64 0%, #07466e 33%, #095273 66%, #07486f 100%);
    background: -webkit-linear-gradient(90deg, #022a64 0%, #07466e 33%, #095273 66%, #07486f 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#022a64", endColorstr="#07486f", GradientType=1);
    min-height: 31dvh;
    color: white;
    z-index: 1;
}

.bg_event2 {
    background: #022a64;
    background: linear-gradient(90deg, #022a64 0%, #07466e 33%, #095273 66%, #07486f 100%);
    background: -moz-linear-gradient(90deg, #022a64 0%, #07466e 33%, #095273 66%, #07486f 100%);
    background: -webkit-linear-gradient(90deg, #022a64 0%, #07466e 33%, #095273 66%, #07486f 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#022a64", endColorstr="#07486f", GradientType=1);
    min-height: 31dvh;
    color: white;
    z-index: 10;
} */

.bg_event-- {
    background: hsla(216, 41%, 79%, 1);
    background: linear-gradient(90deg, hsla(216, 41%, 79%, 1) 0%, hsla(186, 33%, 94%, 1) 100%);
    background: -moz-linear-gradient(90deg, hsla(216, 41%, 79%, 1) 0%, hsla(186, 33%, 94%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(216, 41%, 79%, 1) 0%, hsla(186, 33%, 94%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#B5C6E0", endColorstr="#EBF4F5", GradientType=1);
}

.detailed-event-text {
    font-size: 16px;
    font-weight: 600;
    font-family: Noto Sans;
}

.detailed-event-text-h {
    font-size: 24px;
    font-weight: 600;
    font-family: Noto Sans;
}

.campaign-name {
    font-size: 16px;
    font-weight: 600;
    margin-left: 26px;
    margin-top: 10px;
}

.campaign-url {
    margin-left: 25px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #31197C;
    cursor: pointer;
}

#search {
    background-image: url('../../src/images/searchwhite.png');
    background-position: 96% 50%;
    background-size: 7%;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 100;
    font-size: 16px;
    padding: 12px 35px 12px 12px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
}

.search-border {
    border: 1.5px solid #E3E3E3 !important;
    border-radius: 10px;
    height: 45px;
}

.search-btn {
    color: white;
    border-radius: 6px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.search-btn:hover {
    color: white;
    border-radius: 6px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.fav {
    width: 55px;
    height: 55px;
}

.favauthor {
    width: 65px;
    height: 70px;
}

.share-icon {
    border-color: black;
    color: black;
    cursor: pointer;
}

.heart-icon {
    border-color: #31197C;
    color: #31197C;
    cursor: pointer;
    margin-left: 1px;
}

.heart-o {
    border-color: black;
    color: black;
    cursor: pointer;
    margin-left: 1px;
}

.bookmark-icon {
    border-color: #31197C;
    color: #31197C;
    cursor: pointer;
}

.bookmark-o {
    border-color: black;
    color: black;
    cursor: pointer;
}

.fav-bits-topicicon {
    width: 30px;
    margin-top: 11px;
}

.favbits-topicicon {
    width: 25px;
    margin-top: 10px;
}

.favbits-topicicon-art {
    width: 25px;
}

.favbits-author {
    margin-top: 4px;
    width: 42px;
}

.favbits-author-art {
    width: 42px;
}

/* .latest-art-card {
    height: auto;
    width: 89%;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-bottom: 10px;
    background: #fffffb;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
} */
.latest-art-card {
    height: 4.5rem;
    width: 89%;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    padding-bottom: 6px;
    background: #fffffb;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe, -2px -2px 6px #ffffff;
}

.latest-art-card .row {
    height: 100%;
}

.favbits-title-art {
    display: flex;
    align-items: center;
    /* Vertically center text */
    height: 100%;
    /* Ensure full height of the container */
    margin-left: 5px;
    /* Adjust as needed */
    font-size: 13px;
    color: black;
    text-align: left;
}

.favbits-card {
    height: 62px;
    width: 100%;
    margin-bottom: 10px;
    background: #fffffb;
    /* box-shadow:3px 3px 3px #ebe9e9;  */
    border-radius: 7px;
    border: none;
    /* background: #e0e0e0; */
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
    /* box-shadow:2px 2px 6px 0 rgba(0, 0, 0, 0.15),
             -2px -2px 6px 0 rgba(0, 0, 0, 0.15); */
}

.favbits-art-card-art {
    height: 4.5rem;
    width: 100%;
    padding-bottom: 6px;
    background: #fffffb;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe, -2px -2px 6px #ffffff;
    display: flex;
    align-items: center;
}

.favbits-art-card-art .row {
    height: 100%;
}

.referrals-card {
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 15px;
    background: #fafbee;
    border-radius: 7px;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.adv-status-card {
    height: 45px;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 3px;
    background: rgb(251, 251, 251);
    border: 1px solid lightgrey;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: 1px solid rgb(217, 217, 217);
}

.adv-circles {
    width: 45px;
    position: absolute;
    left: -8px;
    top: -1.5px;
}

.adv-circles-opp {
    width: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.ref-status-card {
    height: 38px;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 3px;
    background: rgb(251, 251, 251);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid lightgrey;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: 1px solid rgb(217, 217, 217);
}

.ref-circles {
    width: 37px;
    position: absolute;
    left: -4px;
    top: -1px;
}

/* .jitbits_card{
        height: 120px;
        width:100%;
        margin-bottom: 10px;
        background: #fffffb;
        border-radius: 7px;
        border:none;
box-shadow:  2px 2px 6px #bebebe,
             -2px -2px 6px #ffffff;
      }
      .favbits-title{
          font-size:13px;
          color:black;
      }
      .favbits-author-auth{
        width:50px;
        height:48px;
        border-radius: 10px;
    }
    .favbits-title-new{
        margin-top:12px;
        font-size:13px;
        color:black;
    }
    
.jitbits-card{
    border-radius: 10px;
    height: 175px;
    width:94%;
    margin-bottom: 16px;
    margin-left:10px;
    border:none;
    background: #f7f7f5;
    box-shadow:  2px 2px 6px #bebebe,
    -2px -2px 6px #ffffff;
}
.jitbits-article-img{
    width:100%;;
    height:100px;
    border-radius:10px;
}
.jitbits-article-heading{
font-family: Noto Sans;
font-style: normal;
font-size: 16px;
line-height: 16px;
color: black;
margin-top:5px;
margin-left:0.3rem;
}
.jitbits-img-Author{
    width:35px;
    height:35px;
    border-radius: 50%;
    margin-left:20px;
    margin-top:10px;
}
.jitbits-img-topicIcon{
    width:32px;
    height:33px;
    margin-top:-9%;
    margin-left: 90%;
}
.author-Card{
    padding:10px;
    width:100%;
    height:240px;
    border-radius:20px;
    background: #F6F6F6;
box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.03);
border-radius: 16px;
}
.jitbits-text{
    font-size: 14px;
    color:black;
    margin-left:5px;
}

.scroll-autorprofile-articles{
    width:294px;
    height:250px;
    display:flex;
    padding:0 6px 6px 6px;
    margin-right:10px;
}
.authorprofile-card{
    border-radius: 10px;
    height: 240px;
    width:290px;
    margin-bottom: 20px;
    border:none;
    background: #fffffb;
    box-shadow:  2px 2px 6px #bebebe,
    -2px -2px 6px #ffffff;
}
.authorprofile-article-heading{
    font-family: Noto Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: black;
    margin-top:14px;
    margin-left:0.3rem;
    }
    .authorprofile-img-topicIcon{
        width:30px;
        height:31px;
        margin-top:-60px;
        margin-left: 86%;t
    }
    .Auth-img{
        width:90px;
    } */
.your-jitbits {
    font-size: 16px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 20px;
    margin-bottom: 15px;
}

.top-jit {
    margin-top: -25px;
}

/* .favbits-article-jit {
    width: 105px;
    height: 118px;
    border-radius: 10px;
} */

.th-heading {
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.tableFixHead th {
    background: #31197C;
    color: white;
    padding: 6px;
}

.purchase-t-left {
    text-align: left;
    margin-left: 2px;
    font-size: 12.5px;
}

.tableFixHead td {
    padding: 8px 1px 8px 5px;
}

.td-sm-text {
    font-size: 12px;
}

.td-p-data {
    font-size: 13px;
}

.td-p-data_ {
    text-align: center;
}

.tshirt-order-img {
    width: 100%;
    height: 100%;
}

.form-fontsize {
    font-size: 12px;
    font-family: Noto Sans;
    padding: 2px;
}

.label-order-text {
    font-size: 14px;
    font-family: Noto Sans;
}

.track-btn {
    font-size: 14px;
    font-family: Noto Sans;
    background-color: #31197C;
    color: white;
    width: auto;
}

.track-btn:hover {
    font-size: 14px;
    font-family: Noto Sans;
    background-color: #31197C;
    color: white;
    width: auto;
}

.favbits-title-new {
    margin-top: 3px;
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.favbits-title-new-opp {
    margin-top: 3px;
    font-size: 16px;
    font-weight: 600;
    color: black;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.select-left-icon {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../src/images/dropdown-black.svg');
    background-repeat: no-repeat;
    background-position: right 7px center;
    background-size: 14px;
    outline: none;
    cursor: pointer;
    width: 100%;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.detailed-adv-card {
    background: linear-gradient(to bottom, #f7e3d7, #fcf2f0, #ebe7f5, #eef2fb);
    border-radius: 35px 35px 0 0;
    padding-top: 5px;
    margin: 2.5rem 4% 0 4%;
    width: 92%;
    height: 82vh;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.detailed-adv-img-border {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    border: 3px solid #31197C;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: -3px;
    margin-left: 9px;
}

.adv-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adv-image-container img {
    max-width: 80px;
    max-height: 80px;
    border-radius: 10%;
}

.jitbits-publish {
    /* float: right;
    font-size: 12px;
    color: black;
    margin-left: 70px;
    font-weight: 600; */

    font-size: 12px;
    color: black;
    margin-right: 6px;
    font-weight: 500;
}

.jit-top {
    margin-top: 14px !important;
    color: black;
    font-size: 14px;
}

.jitbits-art-text {
    color: black;
    font-size: 12px;
}

.html-texT h1 {
    font-size: 1.15rem !important;
    color: #31197C;
    font-weight: 600;
}

.html-texT ol {
    list-style-type: decimal;
}

.html-texT ul {
    list-style-type: disc;
    margin-left: -1rem;
}

.jitbits-img-Author {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 10px;
}


.jitbits-img-topicIcon {
    width: 25px;
    height: 26px;
    margin-top: 18px;
    margin-left: 95%;
}

/* .opp-date {
    font-size: 10px;
    font-weight: 500;
    font-family: Open Sans;
    margin-top: 22px;
    margin-right: 93%;
} */
.jitbits-publish_ {
    float: right;
    font-size: 12px;
    color: black;
    margin-top: 38px;
    margin-left: 120px;
    font-weight: 600;
}

.jitbits_card {
    height: 130px;
    width: 100%;
    margin-bottom: -10px;
    background: #fffffb;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.offers_Card {
    height: 130px;
    width: 97%;
    margin-bottom: -10px;
    background: #fffffb;
    border-radius: 7px;
    margin-left:10px;
    border: none;
    border-top:1px solid lightgray;
    border-left:1px solid lightgray;
    box-shadow: 2px 2px 2px #bebebe,
        -2px -2px 2px #ffffff;
}

.offers_Card:hover {
    transform: scale(1.03);
    border: 2px solid #31197C;
}

.redeem-b {
    height: auto;
    width: auto;
    margin-top: 2px;
    margin-right: 21px;
    float: right;
    background: #31197C;
    color: white;
    font-family: Noto Sans;
}

.redeem-b:hover {
    height: auto;
    width: auto;
    margin-top: 2px;
    margin-right: 21px;
    float: right;
    background: #31197C;
    color: white;
    font-family: Noto Sans;
}

.view-opp-label {
    font-size: 12px;
    font-family: Noto Sans;
    font-weight: 500;
}

.favbits-title {
    font-size: 13px;
    color: black;
    text-align: left;
}

.planned-title {
    font-size: 13px;
    margin-top: -2px;
    color: black;
    text-align: left;
}

.favbits-author-auth {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.your-fav {
    font-size: 16px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 18px;
    margin-bottom: 15px;
}

.your-fav-sme {
    font-size: 16px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 10px;
    margin-top: -0.8rem;
    margin-bottom: 10px;
}

.create-group {
    font-size: 16px;
    font-weight: 600;
    color: #FF942C;
    margin-bottom: 5px;
}

.half-circle {
    width: 100px;
    height: 100px;
    top: 10rem;
    left: 2rem;
    position: absolute;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    border: 3px solid #31197C;
    transform: rotate(91deg);
    border-bottom: 0;
}

.cir {
    transform: translate(50%, 50%) rotate(-90deg) translate(-50%, -50%);
}

.jitbits-card {
    border-radius: 10px;
    height: 175px;
    width: 94%;
    margin-bottom: 16px;
    margin-left: 10px;
    border: none;
    background: #f7f7f5;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.jitbits-article-img {
    width: 100%;
    ;
    height: 100px;
    border-radius: 10px;
}

.jitbits-article-heading {
    font-family: Noto Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: black;
    margin-top: 5px;
    margin-left: 0.3rem;
}

.author-Card {
    padding: 6px 2px;
    width: 100%;
    height: 238px;
    border-radius: 20px;
    background: #F6F6F6;
    box-shadow: 10px 24px 54px rgba(51, 51, 51, 0.03);
    border-radius: 16px;
}

.jitbits-text {
    font-size: 14px;
    color: black;
    margin-left: 5px;
}

.scroll-autorprofile-articles {
    width: 294px;
    height: 250px;
    display: flex;
    padding: 0 6px 6px 6px;
    margin-right: 10px;
}

.authorprofile-card {
    border-radius: 10px;
    height: 240px;
    width: 290px;
    margin-bottom: 20px;
    border: none;
    background: #fffffb;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.authorprofile-article-heading {
    font-family: Noto Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: black;
    margin-top: 14px;
    margin-left: 0.3rem;
}

.authorprofile-img-topicIcon {
    width: 30px;
    height: 31px;
    margin-top: -60px;
    margin-left: 86%;
}

.Auth-img {
    margin-top: -15px;
    width: 70px;
}

.desc-author {
    font-size: 12px;
    margin-left: 27px;
}

#cut-circ {
    stroke-dasharray: 110;
    stroke-dashoffset: 0;
}

#cut-circ-1 {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
}

.circ {
    position: relative;
    bottom: 80px;
    left: 60px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: inline-block;
    background-color: white;
    border-top: 3px solid transparent;
    border-left: 3px solid #31197C;
    border-right: 3px solid #31197C;
    border-bottom: 3px solid #31197C;
    transform: rotate(45deg);
}

.circ-sm {
    position: relative;
    bottom: 60px;
    left: 40px;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    display: inline-block;
    background-color: white;
    border-top: 3px solid transparent;
    border-left: 3px solid #31197C;
    border-right: 3px solid #31197C;
    border-bottom: 3px solid #31197C;
    transform: rotate(90deg);
}

.circ-lg {
    position: relative;
    bottom: 30px;
    left: 20px;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: inline-block;
    background-color: white;
    border-top: 3px solid transparent;
    border-left: 3px solid #31197C;
    border-right: 3px solid #31197C;
    border-bottom: 3px solid #31197C;
    transform: rotate(135deg);
}

/* #outer-circle-lg {
        background: white;
        border-radius: 50%;
        height: 300px;
        width: 300px;
        position: relative;
        border-top: 2px solid #31197C;
        border-left: 2px solid #31197C;
        border-right: 2px solid transparent;
        border-bottom: 2px solid #31197C;
        transform: rotate(155deg);
      }
      #outer-circle {
        background: white;
        border-radius: 50%;
        height: 200px;
        width: 200px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -100px 0px 0px -100px;
        border-top: 2px solid #31197C;
        border-left: 2px solid #31197C;
        border-right: 2px solid #31197C;
        border-bottom: 2px solid transparent;
      }
      #inner-circle {
        position: absolute;
        background: white;
        border-radius: 50%;
        height: 100px;
        width: 100px;
        top: 50%;
        left: 50%;
        margin: -50px 0px 0px -50px;
        border-top: 2px solid #31197C;
        border-left: 2px solid transparent;
        border-right: 2px solid #31197C;
        border-bottom: 2px solid #31197C
      }
      .img-userprof{
          width:60px;
          transform: rotate(204.5deg);
          margin-top: 19%;
      }
      .author-text-rotate{
        font-family: Noto Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: black;
        transform: rotate(204deg);
        margin-top: -72px;
        margin-right:-28px;
        } */
.outer-circle-lg {
    background: white;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    border-top: 2px solid #31197C;
    border-left: 2px solid #31197C;
    border-right: 2px solid transparent;
    border-bottom: 2px solid #31197C;
    transform: rotate(155deg);
    /* 
             Child elements with absolute positioning will be 
             positioned relative to this div 
            */
}

.outer-circle {
    background: white;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -100px 0px 0px -100px;
    border-top: 2px solid #31197C;
    border-left: 2px solid #31197C;
    border-right: 2px solid #31197C;
    border-bottom: 2px solid transparent;
    /* 
             Child elements with absolute positioning will be 
             positioned relative to this div 
            */
}

.inner-circle {
    position: absolute;
    background: white;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    margin: -50px 0px 0px -50px;
    border-top: 2px solid #31197C;
    border-left: 2px solid transparent;
    border-right: 2px solid #31197C;
    border-bottom: 2px solid #31197C
}

.edit-icon-mob {
    color: black;
    position: absolute;
    left: 55%;
    margin-top: -5%;
}

.img-userprof {
    /* width: 60px;
    transform: rotate(205deg);
    margin-top: -4%;
    right: 9%;
    position: absolute; */
    width: 60px;
    transform: rotate(204.5deg);
    margin-top: 19%;
}

.author-text-rotate {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: black;
    transform: rotate(204deg);
    margin-top: -72px;
    margin-right: -28px;
}

/* .likesCount{
                font-family: Noto Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #31197c;
                transform: rotate(204deg);
                margin-top: 65px;
             margin-right:-75px;
                }
                .postCount{
                    font-family: Noto Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #31197c;
                    transform: rotate(204deg);
                    margin-top: -160px;
                    margin-left:-128px;
                }
                .pollCreatedCount{
                    font-family: Noto Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #31197c;
                    transform: rotate(205.5deg);
                    margin-top: -115px;
                    margin-left:55px;
                    }  
                    .pollAnsweredCount{
                        font-family: Noto Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #31197c;
                        transform: rotate(205deg);
                        margin-top: -98px;
                        margin-left:55px;
                        }  
                        
                .followerCount{
                    font-family: Noto Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #31197c;
                    transform: rotate(204deg);
                    margin-top: 140px;
                    float:right;
                    margin-right:140px;
                    }   
                    .followingCount{
                        font-family: Noto Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #31197c;
                        transform: rotate(205deg);
                        margin-top: -27px;
                        margin-left:-350px;
                        }   
                        .monthsActive{
                            font-family: Noto Sans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            text-align: center;
                            color: #31197c;
                            transform: rotate(205deg);
                            margin-top: 52px;
                            margin-left:190px;
                            }  
                            .readArticles{
                                font-family: Noto Sans;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 16px;
                                text-align: center;
                                color: #31197c;
                                transform: rotate(205deg);
                                margin-top: 5px;
                                margin-right:-80px;
                                }       */


.flex-container {
    display: flex;
    position: relative;
    left: -15px;
    right: 20px;
}

.flex-container-1 {
    width: 20%;
    height: 20%;
}

.flex-container-2 {
    width: 60%;
    height: 20%;
}

.flex-container-3 {
    width: 60%;
    height: 20%;
}

.qa-card {
    width: 100%;
    margin-bottom: 10px;
    background: transparent;
    border-radius: 7px;
    border: none;
}

.qa-date {
    font-size: 11px;
    color: black;
    margin-top: 2px;
}

.qa-author {
    margin-top: 4px;
    width: 48px;
}

.popup-box {
    position: fixed;
    display: inline-block;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: -6px;
    left: 0;
}

._box-popup {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    transform: translate(0, 30vh);
    /* margin-top: calc(100vh - 85vh - 20px); */
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
}

._box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
}

.container_class {
    width: 100%;
    height: 7vh;
    background-color: white;
    border-radius: 10px;
    font-family: Noto Sans;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid lightgrey;
}

.close-icon-new {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.outer-circle .followingCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    transform: rotate(204deg);
    position: absolute;
    bottom: -4%;
    right: -20%;
    left: 70%;
}

.outer-circle .followerCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    right: -22%;
    top: 25%;
    transform: rotate(204deg);
}

.outer-circle .pollAnsweredCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    right: 18%;
    top: -22%;
    transform: rotate(204deg);
}

.outer-circle .readArticles {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    left: 7%;
    top: -14%;
    transform: rotate(207deg);
}

.outer-circle .monthsActive {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    top: 95%;
    left: 25%;
    transform: rotate(205deg);
}

.outer-circle .questionsCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    left: -20%;
    bottom: 25%;
    top: 68%;
    transform: rotate(205deg);
}

.inner-circle .answersCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    right: -30%;
    top: -20%;
    transform: rotate(205deg);
}

.inner-circle .opportunitiesCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    left: -39%;
    bottom: 62%;
    transform: rotate(205deg);
}

.inner-circle .likesCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    left: 40%;
    bottom: -50%;
    transform: rotate(205deg);
}

.inner-circle .pollCreatedCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    left: 26%;
    top: -53%;
    transform: rotate(205deg);
}

.inner-circle .postCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    right: -36%;
    top: 60%;
    transform: rotate(205deg);
}

.inner-circle .referralCount {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #31197c;
    position: absolute;
    left: -34%;
    bottom: -12%;
    transform: rotate(205deg);
}

.close-icon-cross {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(16% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.single_quiz_card {
    border: 1px solid #efefef;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.quiz_checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
    z-index: 999;
    cursor: pointer;
}

.quiz_checkbox:checked~.single_quiz_card {
    border: 1.5px solid #FF942C;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
}

.quiz_checkbox:checked:hover~.single_quiz_card {
    border: 1.5px solid #FF942C;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
}

.single_check_card {
    border: 1px solid #efefef;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.check_checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
    cursor: pointer;
}

.check_checkbox:checked~.single_check_card {
    border: 1.5px solid #FF942C;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
}

.check_checkbox:checked:hover~.single_check_card {
    border: 1.5px solid #FF942C;
    height: 80px;
    width: 78px;
    padding: 7px 3px 3px 5px;
    border-radius: 10px;
}

.form_check {
    font-size: 12px;
}

.createopportunity-card {
    border-radius: 10px;
    height: auto;
    width: 100%;
    margin-bottom: 8px;
    border: none;
    background: #f7f7f5;
}

.card-opp-top {
    padding-top: 0.6rem !important;
}

div .adv-body {
    margin-left: 5.5%;
}

div .adv-shadow {
    width: 120%;
    height: 102px;
    display: inline-flex;
}

section:first-child {
    width: 70%;
    height: 92px;
    border-right: 3px dotted grey;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #d8dcf4;
    display: inline-block;
}

section:last-child {
    width: 55%;
    height: 92px;
    background: radial-gradient(circle at right bottom, transparent 0, #c0b4f4 0) top left / 50% 55px no-repeat,
        radial-gradient(circle at right top, transparent 0, #c0b4f4 0) bottom left / 50% 56px no-repeat;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    display: block;
}

/* section:first-child {
    width: 70%;
    height: 108px;
    background: radial-gradient(circle at left bottom, transparent 16px, #e3e1e1 0) top left / 100% 56px no-repeat,
        radial-gradient(circle at left top, transparent 16px, #e3e1e1 0) bottom left / 100% 55px no-repeat;
    border-right: 3px dotted white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: inline-block;
}

section:last-child {
    width: 55%;
    height: 108px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: radial-gradient(circle at right bottom, transparent 15px, #ebebeb 0) top left / 50% 55px no-repeat,
        radial-gradient(circle at right top, transparent 15px, #ebebeb 0) bottom left / 50% 56px no-repeat;
    display: inline-block;
} */


.art-desc-position {
    position: fixed;
    z-index: 100;
    margin-top: -10px;
    background-color: white;
}

.bg-back {
    background: white;
    margin-top: -5px;
}

.title-Image {
    text-align: center;
    background: white;
}

/* .image-des-top {
    margin-top: 8rem;
} */

.desc-Image {
    z-index: -1;
    border: 1px solid #bebebe;
    border-radius: 10px;
    width: 50%;
    height: 100%;
}

.dot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 200;
}

.passageStops {
    background-color: black;
    mix-blend-mode: overlay;
    width: 33.3%;
    top: calc(1em + 3px);
}

#passage,
.passageStops {
    height: 10px;
}

#passageWrapper,
.passageStops {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
}

#passage {
    background: violet;
    margin: 0 0 20px 0;
    filter: contrast(120%);
}

#passageWrapper {
    background-color: white;
    height: 40px;
    top: 0px;
}

.passageStops:nth-child(4) {
    margin-left: 33.3%;
}

.passageStops:nth-child(6) {
    margin-left: 66.6%;
}


.sections {
    height: 400px;
    margin-bottom: 20px;
    text-align: center;
}

main {
    width: 100%;
    margin: auto;
    margin-top: 40px;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
}

.s-cards {
    font-size: 15px;
    font-family: Noto Sans;
    color: black;
    margin-top: -2px;
}

.stars-outer {
    display: inline-block;
    position: relative;
    font-family: FontAwesome;
}

.stars-outer::before {
    content: "\f006 \f006 \f006 \f006 \f006";
}

.stars-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
}

.stars-inner::before {
    content: "\f005 \f005 \f005 \f005 \f005";
    color: #f8ce0b;
}

.rating-o {
    border: none;
    float: left;
}

.rating-o>input {
    display: none;
}

.rating-o>label:before {
    margin: 1px;
    cursor: pointer;
    font-size: 1em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating-o>.half:before {
    content: "\f089";
    position: absolute;
}

.rating-o>label {
    color: #ddd;
    float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating-o>input:checked~label,
/* show gold star when clicked */
.rating-o:not(:checked)>label:hover,
/* hover current star */
.rating-o:not(:checked)>label:hover~label {
    color: #FF942C;
}

/* hover previous stars in list */

.rating-o>input:checked+label:hover,
/* hover current star when changing rating */
.rating-o>input:checked~label:hover,
.rating-o>label:hover~input:checked~label,
/* lighten current selection */
.rating-o>input:checked~label:hover~label {
    color: #FF942C;
}

.sceemlore-lg-card {
    width: 100%;
    background: #fffffb;
    border-radius: 20px;
    margin: auto;
    height: 70vh;
}

.sceemlore-c-card {
    width: 97.5%;
    background: #fffffb;
    border-radius: 0px;
    margin: 5px;
    height: 71vh;
    border: 1px solid black;
}

.scm-back-left {
    cursor: pointer;
    text-align: center;
    width: 40px;
    font-size: 20px;
    font-weight: 700;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 5px 5px 10px #dcdcdc,
        -5px -5px 10px #ffffff;
    border: none !important;
    position: absolute;
    bottom: 4.5rem;
    left: 10px;
    opacity: 0.6;
    outline: none;
}

.scm-lore-left {
    cursor: pointer;
    text-align: center;
    width: 40px;
    font-size: 20px;
    font-weight: 700;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 5px 5px 10px #dcdcdc,
        -5px -5px 10px #ffffff;
    border: none !important;
    position: absolute;
    bottom: 32%;
    left: 15px;
    opacity: 0.6;
}

.scm-lore-right {
    cursor: pointer;
    text-align: center;
    width: 40px;
    font-size: 20px;
    font-weight: 700;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 5px 5px 10px #dcdcdc,
        -5px -5px 10px #ffffff;
    border: none !important;
    position: absolute;
    bottom: 32%;
    right: 15px;
    opacity: 0.6;
}

button.scm-lore-right:focus {
    outline: none;
}

button.scm-lore-left:focus {
    outline: none;
}

button.scm-back-left:focus {
    outline: none;
}

.sceemlore-c-inner-card {
    width: 96.6%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    background: #fffffb;
    border-radius: 0px;
    margin: auto;
    height: 69vh;
    border: 1px solid black;
}

.sceem-lore {
    margin: auto;
}

.sceem-lore-auto {
    margin: 0.5rem auto auto auto;
}

.center-sceemlore-text-title {
    width: 100%;
    border: none;
    font-size: 20px;
    font-weight: 600;
}

.center-sceemlore-text {
    margin: auto;
    width: 100%;
    border: none;
    font-size: 25px;
}

.orange {
    color: #FF942C;
}

.grey {
    color: lightgrey;
}

.sceemlore-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #FF942C;
}

.sceemlore-card {
    width: 100%;
    background: #fffffb;
    border-radius: 7px;
    padding: 4px;
    color: black;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.sceemlore-card-topic {
    width: 100%;
    background: #fffffb;
    border-radius: 7px;
    border: 1px solid green;
    padding: 4px;
    color: black;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.sceemlore-heading {
    color: #FF942C;
    font-weight: 600;
    font-size: 22px;
    font-family: Noto Sans;
}

.sceemlore-heading-title {
    color: #FF942C;
    font-weight: 600;
    font-size: 22px;
    font-family: Noto Sans;
    margin-top: 15vh;
}

.sceemlore-topicImg {
    position: absolute;
    right: 10px;
}

.sceemlore-back_arrow {
    top: 9%;
    margin-left: 0.95rem;
    width: 10%;
    position: fixed;
}

.claims-back_arrow {
    top: 9%;
    margin-left: 1.3rem;
    width: 10%;
    position: fixed;
}

.sceemlore-admin-name {
    color: #FF942C;
    font-weight: 600;
    font-size: 16px;
    font-family: Noto Sans;
}

.sceemlore-admin {
    position: absolute;
    bottom: 5rem;
    left: 15px;
}

.smeDescText {
    width: 100%;
    overflow-y: auto;
    height: 5.5rem;
    font-size: 12px;
    font-family: Noto Sans !important;
    margin: 2px;
}

.details-btn {
    width: 6rem;
    height: 30px;
    color: white;
    border-radius: 10px;
    background: #31197C;
    position: absolute;
    right: 3px;
    bottom: 1px;
    font-size: 14px;
    font-weight: 500;
    padding: 1px 0 2.5px 0;
}

.details-btn:hover {
    width: 6rem;
    height: 30px;
    color: white;
    border-radius: 10px;
    background: #31197C;
}

.back_arrow-sme-profile {
    top: 4.4rem;
    left: 2px;
    width: 8%;
    position: absolute;
    z-index: 1;
}


.showPlay-btn {
    width: 5rem;
    height: 25px;
    color: #31197C;
    border-radius: 4px;
    border: 1px solid #31197C;
    font-size: 12px;
    position: absolute;
    right: 20px;
    z-index: 1;
    font-weight: 500;
    padding: 1px 0 1.5px 0;
}

.showPlay-btn:hover {
    width: 5rem;
    height: 25px;
    color: #31197C;
    border-radius: 4px;
    border: 1px solid #31197C;
}

.showPlay-btn-sequence {
    width: 5rem;
    height: 25px;
    color: #31197C;
    border-radius: 4px;
    border: 1px solid #31197C;
    font-size: 12px;
    position: absolute;
    right: 20px;
    z-index: 1;
    font-weight: 500;
    padding: 1px 0 1.5px 0;
}

.showPlay-btn-sequence:hover {
    width: 5rem;
    height: 25px;
    color: #31197C;
    border-radius: 4px;
    border: 1px solid #31197C;
}

.howToPlay {
    text-align: left;
}

.card-register-btn {
    position: absolute;
    right: 15px;
    bottom: 4.6rem;
    width: 6rem;
    height: 30px;
    color: white;
    border-radius: 10px;
    background: #31197C;
    font-size: 14px;
    font-weight: 500;
    padding: 1px 0 3px 0;
}

.card-register-btn:hover {
    width: 6rem;
    height: 30px;
    color: white;
    border-radius: 10px;
    background: #31197C;
}

.card-prev-register-btn {
    width: 7.5rem;
    height: 38px;
    color: white;
    border-radius: 12px;
    background: #FF942C;
    position: absolute;
    left: 10px;
    bottom: 5rem;
    font-size: 16px;
    font-weight: 500;
}

.card-prev-register-btn:hover {
    width: 7.5rem;
    height: 38px;
    color: white;
    border-radius: 12px;
    background: #FF942C;
}

.sceemlore-card-h-title {
    color: black;
    font-size: 20px;
    font-weight: 600;
    font-family: Noto Sans;
}

.sceemlore-card-h-desc {
    color: black;
    font-size: 15px;
    font-weight: 600;
    font-family: Noto Sans;
}

.prevIcon {
    position: absolute;
    bottom: 4.5rem;
    left: 10px;
    font-size: 2rem;
    cursor: pointer;
}

.nextIcon {
    position: absolute;
    bottom: 4.5rem;
    right: 10px;
    font-size: 2rem;
    cursor: pointer;
}

.congrats-i {
    text-align: center;
    line-height: 50vh;
}

.congrats-review {
    text-align: center;
    line-height: 56vh;
}

.thanks-i {
    text-align: center;
}

hr.congrats {
    border-top: 3px solid black;
    margin-top: -3%;
}

hr.card {
    border-top: 3px solid black;
    border-radius: 0;
    margin-top: -15%;
    width: 95%;
    margin-left: 8px;
    margin-right: 8px;
}

.congrats-text {
    font-size: 18px;
    font-weight: 600;
    font-family: Noto Sans;
    text-align: center;
}

.rating {
    border: none;
    float: left;
    position: absolute;
    bottom: 3.9rem;
    left: 10px;
}

.rating>input {
    display: none;
}

.rating>label:before {
    margin: 2px;
    cursor: pointer;
    font-size: 1.5em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating>.half:before {
    content: "\f089";
    position: absolute;
}

.rating>label {
    color: #ddd;
    float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,
/* show gold star when clicked */
.rating:not(:checked)>label:hover,
/* hover current star */
.rating:not(:checked)>label:hover~label {
    color: #FF942C;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,
/* hover current star when changing rating */
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
/* lighten current selection */
.rating>input:checked~label:hover~label {
    color: #FF942C;
}

.sceem-certificate {
    width: 30%;
    margin-top: -5%;
}

.appreciation-certificate {
    margin-top: -7%;
    width: 76%;
}

/* .appreciation-certificate {
    margin-top: -7%;
    width: 84%;
    font-family: 'Lucida Calligraphy New', serif;
    font-weight: 500;
} */
.certificate {
    font-family: 'Lucida Calligraphy New', serif;
    font-size: 40px;
    margin-top: -2%;
}

.appreciation {
    position: absolute;
    top: 35.2%;
    left: 10%;
    right: 10%;
    font-family: 'Lucida Calligraphy New', serif;
    font-size: 20px;
}

.certificate-of {
    font-family: 'Lucida Calligraphy New', serif;
    font-size: 15px;
}

.certificate-cName {
    /* margin-top: -24%; */
    margin-top: 1.5rem;
    font-weight: 600;
    font-family: 'Century Schoolbook';
    font-size: 25px;
}

.certificate-completed {
    font-size: 15px;
    font-family: 'Century Schoolbook';
    font-weight: 500;
}

.title-and-version {
    font-weight: 500;
    font-family: 'Century Schoolbook';
    font-size: 16px;
}

.weight {
    font-weight: 700;
}

.normal-weight {
    font-weight: 400;
}

.century-schoolbook {
    font-family: 'Century Schoolbook';
    font-weight: 500;
}

.certificate-on {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Century Schoolbook';
}

.badge-logo-sceem {
    width: 20%;
    position: absolute;
    bottom: 2%;
    right: 10px;
}

.announcement-sceem-logo {
    width: 25%;
    height: 25%;
}

.announcement-sceem-icon {
    width: 24%;
    height: 24%;
}

.tableFixed {
    overflow-y: auto;
    height: 75vh;
    width: 100%;
}

.tableFixed thead th {
    position: sticky;
    top: 0;
}

.tableFixed th {
    background: #31197C;
    color: white;
    padding: 5px;
}

.tableFixed td {
    padding: 8px 1px 8px 5px;
}


.tableRewards {
    overflow-y: auto;
    height: 58vh;
    width: 100%;
}

.tableRewards thead th {
    position: sticky;
    top: 0;
}

.tableRewards th {
    background: #31197C;
    color: white;
    padding: 5px;
}

.tableRewards td {
    padding: 8px 1px 8px 5px;
}

._rewards {
    font-size: 15px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 20px;
    margin-bottom: 5px;
}

.reward_b {
    position: relative;
    top: 1rem;
}

.lb-card {
    border-radius: 18px;
    background: white;
    height: auto;
    z-index: 20;
    border: none;
    width: auto;
    padding: 5px;
}

.lb-entire-card {
    border-radius: 18px;
    background: #d1e5f5;
    height: auto;
    border: none;
    width: auto;
    padding: 10px;
}

/* .bg-rank-1{
    background-color: #31197c;
    width:15px;
    height:15px;
    border-radius: 50%;
} */
.rank-1 {
    background-color: #31197c;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    color: white;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    left: 68%;
}

.rank-2 {
    background-color: #31197c;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    color: white;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    top: 18%;
    left: 63%;
}

.rank-3 {
    background-color: #31197c;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    color: white;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    top: 19%;
    left: 64%;
}

.rank-4 {
    background-color: #31197c;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    color: white;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
}

.term_Usage {
    height: 24vh;
    overflow-y: auto;
    width: 98%;
    font-size: 14px;
    padding-left: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: -13.3rem;
}

.term_borderText {
    position: absolute;
    margin-top: -10px;
    width: 34%;
    background-color: white;
    left: 34%;
    right: 30%;
    text-align: center;
    color: #FF942C;
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
}

.term_Border {
    padding: 0.3rem;
    width: 98%;
    margin: 0rem auto 1rem auto;
    border: 2px solid #FF942C;
    border-radius: 20px;
}

.term-bubble {
    --arrow-w: 0.3em;
    --arrow-h: 1em;
    --bubble-corners: 0;
    --w: 20em;
    --border-size: 2px;
    --text-color: black;
    --bubble-color: white;
    --border-color: black;
}

.term-bubble {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: var(--text-color);
    background: var(--bubble-color);
    padding: 0.8em;
    position: relative;
    border-radius: var(--bubble-corners);
    width: var(--w);
    box-shadow: 0 0 0 var(--border-size) var(--border-color);
}

.term-bubble.round {
    --bubble-corners: 0.8em;
}

.term-bubble.circle {
    --bubble-corners: 100%;
    padding: 1.5em;
}

.term-bubble:before,
.term-bubble:after {
    content: "";
    position: absolute;
    bottom: calc(-1 * var(--arrow-h) * 2 + 0.5px);
    left: 50%;
    border-style: solid;
    border-width: var(--arrow-h) var(--arrow-w);
    border-color: var(--bubble-color) transparent transparent;
}

.term-bubble:before {
    border-width: calc(var(--arrow-h) + var(--border-size)) calc(var(--arrow-w) + var(--border-size));
    border-color: var(--border-color) transparent transparent;
    bottom: calc(-1 * var(--arrow-h) * 2 - 2.5*var(--border-size));
    left: calc(50% - var(--border-size));
}

.term-bubble.r:after {
    border-color: transparent transparent transparent var(--bubble-color);
    bottom: auto;
    left: auto;
    border-width: var(--arrow-w) var(--arrow-h);
    top: calc(50% - var(--arrow-w));
    right: calc(-1 * var(--arrow-h) * 2 + 0.5px);
}

.term-bubble.r:before {
    border-width: calc(var(--arrow-w) + var(--border-size)) calc(var(--arrow-h) + var(--border-size));
    border-color: transparent transparent transparent var(--border-color);
    bottom: auto;
    left: auto;
    top: calc(50% - var(--arrow-w) - var(--border-size));
    right: calc(-1 * var(--arrow-h) * 2 - 2.5*var(--border-size));
}

.quiz-txt {
    font-size: 18px;
    font-weight: 600;
    font-family: Noto Sans;
    color: #ff942c;
    padding: 5px;
    text-align: left;
    margin: 5px 10px;
}

.quiz-topic {
    font-size: 16px;
    font-weight: 600;
    font-family: Noto Sans;
    color: white;
    border-radius: 10px;
    padding: 10px;
    width: 90%;
    height: 3rem;
    text-align: center;
    border: 1px solid #31197C;
    margin: 5px 1rem;
    background: #31197C;
    position: absolute;
    bottom: 4.1rem;
    left: 46%;
    transform: translate(-50%, 0);
}

.quiz-topic:hover {
    font-size: 16px;
    font-weight: 600;
    font-family: Noto Sans;
    color: white;
    border-radius: 10px;
    padding: 10px;
    width: 90%;
    height: 3rem;
    text-align: center;
    border: 1px solid #31197C;
    margin: 5px 1rem;
    background: #31197C;
    position: absolute;
    bottom: 4.1rem;
    left: 46%;
    transform: translate(-50%, 0);
}

.quiz-t {
    font-size: 16px;
    font-weight: 500;
    font-family: Noto Sans;
    color: black;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    border: 1px solid lightgray;
    margin: 8px 1rem;
    background: white;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.quiz-t-bg {
    font-size: 16px;
    font-weight: 500;
    font-family: Noto Sans;
    color: black;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    border: 3px solid #31197C;
    margin: 8px 1rem;
    background: white;
}

.quiz-s-btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 4.2rem;
    width: auto;
    height: 2.5rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.quiz-s-btn:hover {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 4.2rem;
    width: auto;
    height: 2.5rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.quiz-f-btn {
    position: static;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 4.2rem;
    width: auto;
    height: 2.5rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.quiz-f-btn:hover {
    position: static;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 4.2rem;
    width: auto;
    height: 2.5rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.quiz-ques {
    font-size: 14px;
    font-weight: 600;
    font-family: Noto Sans;
    color: black;
    border-radius: 10px;
    text-align: left;
    margin: 5px;
}

.option {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}

.option.correct {
    background-color: lightgreen;
}

.option.wrong {
    background-color: lightcoral;
}

.quiz-a-left {
    cursor: pointer;
    text-align: center;
    width: 40px;
    font-size: 20px;
    font-weight: 700;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 5px 5px 10px #dcdcdc,
        -5px -5px 10px #ffffff;
    border: none !important;
    position: absolute;
    bottom: 20%;
    left: 15px;
    opacity: 0.6;
}

.quiz-a-right {
    cursor: pointer;
    text-align: center;
    width: 40px;
    font-size: 20px;
    font-weight: 700;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 5px 5px 10px #dcdcdc,
        -5px -5px 10px #ffffff;
    border: none !important;
    position: absolute;
    bottom: 20%;
    right: 15px;
    opacity: 0.6;
}

.abt-u {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: Noto Sans;
    color: black;
    margin-top: -10px;
}

.abt-u_ {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: Noto Sans;
    color: black;
}

.abt-u_p {
    margin-left: 20px;
    margin-top: -6px;
    font-size: 14px;
    font-weight: 600;
    font-family: Noto Sans;
    color: black;
}

.register-sme-box {
    border: 1px solid gray;
    border-radius: 20px;
    height: 64vh;
    margin: 0px 20px;
}

.sme-intro-box {
    border: 1px solid gray;
    border-radius: 20px;
    height: 28vh;
    margin: 0px 8px;
}

.sme-intro-box-aboutme {
    /* height: 37vh; */
    height: 36vh;
    width: 96%;
    font-size: 13px;
    margin: 0px 8px;
    overflow-y: auto;
}

.custom-textbox {
    padding: 10px;
    font-size: 16px;
    border: 1px solid gray;
    border-radius: 20px;
    height: 70vh !important;
    width: 100%;
    margin: 0px 20px;
}

.sme-next-btn {
    position: absolute;
    right: 1.4rem;
    bottom: 6.5rem;
    width: 5rem;
    border: 1px solid #31197C;
    background: #31197C;
    font-size: 14px;
    font-family: Noto Sans;
    color: white;
    font-weight: 600;
}

.sme-next-btn:hover {
    border: 1px solid #31197C;
    background: #31197C;
    color: white;
}

.sme-connect-next-btn {
    width: 5rem;
    border: 1px solid #31197C;
    background: #31197C;
    font-size: 14px;
    font-weight: 600;
    float: right;
    font-family: Noto Sans;
    color: white;
}

.sme-connect-next-btn:hover {
    border: 1px solid #31197C;
    background: #31197C;
    color: white;
}

.sme-off-text {
    font-size: 11px;
    color: black;
}

.prev-btn-sme {
    width: 5.5rem;
    border: 1px solid #31197C;
    background: #31197C;
    font-size: 14px;
    font-weight: 600;
    font-family: Noto Sans;
    color: white;
}

.prev-btn-sme:hover {
    border: 1px solid #31197C;
    background: #31197C;
    color: white;
}

.profile-btn-sme {
    width: 6.1rem;
    border: 1px solid #31197C;
    border-top: 1px solid lightblue;
    background: #31197C;
    font-size: 14px;
    border-radius: 10px;
    font-family: Noto Sans;
    font-weight: 600;
    color: white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-btn-sme:hover {
    border: 1px solid #31197C;
    background: #31197C;
    color: white;
}

.slot-time {
    font-size: 12px;
    font-family: Noto Sans;
}

.sme-connect-box {
    border: 1px solid gray;
    border-radius: 20px;
    margin: 0px 7px;
    margin-top: -5px;
}

.sme-abt-u_ {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 600;
    font-family: Noto Sans;
    color: black;
}

.sme-txt-size {
    font-size: 11px;
    font-family: Noto sans;
}

.sme-m-top-txt {
    margin-top: -3px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -5px;
}

.month-heading {
    margin: 0 20px;
    font-size: 14px;
    font-weight: 600;
}

.nav-arrows button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    margin-right: 2px;
}

.calendar {
    display: flex;
    flex-direction: column;
}

.days-of-week {
    display: flex;
    font-size: 11px;
    font-family: Noto Sans;
}

.day-of-week {
    flex: 1;
    text-align: center;
    border: 1px solid #ccc;
    margin-top: -3px;
    border-radius: 3px;
}

.calendar-dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-size: 11px !important;
    font-family: Noto Sans;
}

.calendar-date {
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    font-size: 11px;
    font-family: Noto Sans;
}

.selected {
    background-color: lightblue;
    font-size: 11px;
    font-family: Noto Sans;
}

.past-date {
    background-color: rgb(232, 229, 229);
}

.sme-map {
    height: 68vh;
}

.sme-map1 {
    height: 39vh;
}

.sme-map2 {
    height: 68vh;
}

.sme-planned-border {
    border-radius: 20px;
    border: 1px solid gray;
    height: 70vh;
}

.sme-planned-border-abc {
    border-radius: 20px;
    border: 1px solid gray;
    height: 70vh;
    margin-left: 12px;
    margin-right: 12px;
    padding: 2px 0.7rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
}

.sme-booked-border-abc-B {
    border-radius: 20px;
    border: 1px solid gray;
    height: auto;
    margin-left: 12px;
    margin-right: 12px;
    padding: 2px 0.7rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px
        /* justify-content: center;
    align-items: center; */
}

/* .sme-planned-border-abc {
    border-radius: 20px;
    border: 1px solid gray;
    height: 70vh;
    margin-left: auto;
    margin-right: auto;
    padding: 2px 0.7rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
.status-indicator-container {
    display: flex;
    align-items: center;
}

.status-indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 2px;
}

.status-indicator-container span {
    font-size: 12px;
}

.sme-slot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sme-slot-item {
    width: calc(25% - 16px);
    /* 25% width for each item with margin adjustments */
    margin: 8px;
    /* Adjust margin as needed */
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .sme-slot-item {
        width: calc(50% - 16px);
        /* Two items per row on smaller screens */
    }
}

.sme-planned-card {
    padding: 2px 4px 3px 4px;
    width: 95%;
    background: #fffffb;
    border-radius: 7px;
    border: 2px solid black;
    margin: 2px 10px;
    height: 50px;
}

.rounded-img-border-sme {
    border: none;
    border-color: #ff942c;
    border-radius: 50%;
    transition: border-color 0.3s ease-in-out;
    cursor: pointer;
}

.rounded-img-border-sme:hover {
    border: 2px solid #ff942c;
    border-radius: 50%;
    transition: border-color 0.3s ease-in-out;
}

.sme-booking-slot-popup {
    display: flex;
    /* Use flexbox */
    justify-content: space-between;
    /* Distribute space between elements */
    align-items: center;
    /* Align items vertically */
    width: 100.5%;
    height: 3rem;
    color: white;
    font-family: Noto Sans;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: #31197C;
    position: absolute;
    top: -23px;
    left: -1px;
}

.sme-booking-slot-popup-planned {
    display: flex;
    /* Use flexbox */
    justify-content: space-between;
    /* Distribute space between elements */
    align-items: center;
    /* Align items vertically */
    height: 3rem;
    color: white;
    font-family: 'Noto Sans';
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #31197C;
    position: absolute;
    top: -1.5rem;
    /* Adjust to match the height of the popup */
    left: -1px;
    right: 0;
    z-index: 2;
}


/* .sme-booking-slot-popup-planned {
    width: 100.5%;
    height: 3rem;
    color: white;
    font-family: 'Noto Sans';
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #31197C;
    position: absolute;
    top: -1.4rem;
    left: -1px;
    right:0;
    z-index: 2; 
} */
/* .sme-booking-slot-popup-bottom {
    width: 100.5%;
    height: auto;
    color: white;
    font-family: Noto Sans;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: #31197C;
    position: absolute;
    bottom: 0px;
    left: -1px;
} */
.sme-booking-slot-popup-bottom {
    width: 100.5%;
    /* Adjusted width to accommodate border */
    height: auto;
    color: white;
    font-family: 'Noto Sans';
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: #31197C;
    position: relative;
    /* Changed to relative */
    bottom: -10px;
    left: -1px;
    right: 0;
    z-index: 1;
    margin-top: -1px;
    word-wrap: break-word;
}

.sme-profile-bg-booking {
    background-color: #DCEAF7;
    margin: 6px 0px;
    border-radius: 10px;
    padding: 6px;
    height: auto;
    color: black;
    margin-top: 2rem;
    font-weight: 700;
    font-size: 18px;
    font-family: Noto Sans;
}

.sme-profile-bg-booking-req {
    width: 100%;
    background-color: #DCEAF7;
    margin: 4px 0px;
    padding: 4px 1px;
    border-radius: 10px;
    height: auto;
    color: black;
    margin-top: 2rem;
    font-weight: 700;
    font-size: 18px;
    font-family: Noto Sans;
    /* white-space: nowrap; */
    word-wrap: break-word;
}

.sme-profile-bg-booking-req_ {
    width: 96%;
    background-color: #DCEAF7;
    margin: 4px 0px;
    padding: 4px 1px;
    border-radius: 10px;
    height: auto;
    color: black;
    margin-top: 2rem;
    font-weight: 700;
    font-size: 18px;
    font-family: Noto Sans;
    /* white-space: nowrap; */
    word-wrap: break-word;
    margin-bottom: 10px;
}

.sme-slot-popup-heading {
    font-size: 18px;
    font-family: Noto Sans;
    font-weight: 600;
    color: #ff942c;
}

._picker {
    color: #ff942c;
    font-weight: 600;
}

#bg-sme-curve {
    background-image: url('../../src/images/curve.svg');
    background-repeat: no-repeat;
    background-position: right top;
    background-attachment: fixed;
}

.sme-animation {
    width: 95%;
    animation-name: animateIn;
    animation-duration: 450ms;
    animation-delay: calc(var(--animation-order) * 400ms);
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}


@keyframes animateIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.containerCurve {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 500%;
    vertical-align: middle;
    overflow: hidden;
}

.selectedTickCross {
    border: 2px solid #ff942c;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 1px 2px;
}

.offerings-card-smeprofile {
    background: #d7deef;
    background-image: linear-gradient(to right, #d7deef, #9DB2DD);
    width: 100%;
    height: 5.5rem;
    border: none;
    border-radius: 15px;
    padding: auto;
    overflow: hidden;
    /* Hide overflow for animation */
    position: relative;
}

.offerings-card-smeprofile_ {
    margin-left: 0.7rem;
    background: #d7deef;
    background-image: linear-gradient(to right, #d7deef, #9DB2DD);
    width: 78%;
    height: 5.5rem;
    border: none;
    border-radius: 15px;
    padding: auto;
    position: relative;
    overflow-wrap: break-word;
    overflow: hidden;
}

.offerings-wrapper {
    position: absolute;
    left: -100%;
    overflow-wrap: break-word;
    animation: slideRight 2s forwards;
    width: 90%;
}

.offerings-text {
    font-size: 12px;
    font-family: Noto Sans;
    line-height: 14px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}


@keyframes slideRight {
    0% {
        left: -100%;
        /* Initial position: outside the card */
    }

    100% {
        left: 50%;
        /* Final position: centered */
        transform: translateX(-50%);
        /* Center the element horizontally */
    }
}

@keyframes slideRightT {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(10%);
    }
}

.topics-card-sme {
    margin-left: 0.7rem;
    background: #d7deef;
    background-image: linear-gradient(to right, #d7deef, #9DB2DD);
    width: 78%;
    height: 5.5rem;
    border: none;
    border-radius: 15px;
    padding: auto;
    position: relative;
    /* Ensure relative positioning for absolute positioning */
}

.p-fee {
    font-size: 15px;
    font-family: Noto Sans;
    font-weight: 600;
    text-align: left;
}

.p-fee-text-sm {
    font-size: 12px;
    font-family: Noto Sans;
    font-weight: 500;
    text-align: left;
}

.your-jitbits-fees {
    font-size: 16px;
    font-weight: 600;
    color: #FF942C;
    margin-left: 17px;
    margin-bottom: 15px;
}

.fees-next-btn {
    width: 5.5rem;
    color: white;
    background: #31197C;
    position: absolute;
    right: 8px;
    bottom: 4.4rem;
    font-size: 14px;
    font-weight: 600;
}

.fees-next-btn:hover {
    color: white;
    background: #31197C;
}

.fees-prev-btn {
    width: 5.5rem;
    color: white;
    background: #31197C;
    position: absolute;
    left: 8px;
    bottom: 4.4rem;
    font-size: 14px;
    font-weight: 600;
}

.fees-prev-btn:hover {
    color: white;
    background: #31197C;
}

.sme-fee-box-b {
    border: 1px solid gray;
    border-radius: 20px;
    margin: 0px 7px;
    height: 70vh;
    padding: 2px 10px;
}

.custom-progress-bar .progress-bar {
    background-color: #ff942c;
    margin-top: -5px;
    height: 30px;
}

.promote-prof-text {
    font-family: "Noto Sans";
    font-size: 16px;
}

.custom-form-control {
    padding-left: 0.35rem !important;
    padding-right: 0.35rem !important;
}