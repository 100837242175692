.pricing-main {
    width: 100%;
    height: 180vw;
    margin-top: -4rem;
}

.pricing-left-right {
    /* margin-left:1.6rem;
    margin-right:0.5rem; */
    margin-left: 10%;
    margin-right: 10%;
}

.pricing-ml {
    margin-left: 4%;
    margin-right: 4%;
}

.no-scroll-pricing {
    width: 330px;
    height: 430px;
    margin-top: -4.3rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
}
.sequence-card-game {
    margin-top: 3rem;
    width: 24.4vw;
    height: 17.5vh;
    position: absolute;
    bottom:1px !important;
    display: flex;
    color:white;
    border-radius: 10px;
    overflow-inline:hidden ;
    background-color:#31197c;
    border:1px solid white;
    /* margin-top: 3rem;
    position: absolute;
    width: 24.4vw;
    height: 17.5vh;
    display: flex;
    color:white;
    border-radius: 10px;
    overflow-inline:hidden ;
    background-color:#31197c;
    border:1px solid white; */
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
}
.sequence-card-game:hover {
    transform: scale(1.11) !important;
    z-index:5 !important;
}
/* .card {
    position: absolute;
    width: 200px;
    height: 300px;
    background-color: #ffffff;
    border: 1px solid #000000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  #card1 {
    left: 0;
    top: 0;
    z-index: 5;
  }
  
  #card2 {
    left: 20px;
    top: 0;
    z-index: 4;
  }
  
  #card3 {
    left: 40px;
    top: 0;
    z-index: 3;
  }
  
  #card4 {
    left: 60px;
    top: 0;
    z-index: 2;
  }
  
  #card5 {
    left: 80px;
    top: 0;
    z-index: 1;
  } */
.s-card{
    margin-top: 3rem;
    position: absolute;
    width: 28.4vw;
    height: 21vh;
    display: flex;
    color:white;
    border-radius: 10px;
    overflow-inline:hidden ;
    background-color:#31197c;
    border:1px solid white;
}
.s-card:hover {
    transform: scale(1.11) !important;
    z-index:5 !important;
}
.semicircle-card {
    /* position: absolute;
    width: 200px;
    height: 100px;
    border-radius: 100% 100% 0 0;
    background-color: #f0f0f0;
    overflow: hidden;
    display: flex;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); */
    margin-top: 3rem;
    position: absolute;
    width: 24.4vw;
    height: 17.5vh;
    display: flex;
    color:white;
    border-radius: 100% 100% 0% 0%;
    overflow-inline:hidden ;
    background-color:#31197c;
    border:1px solid white;
  }
/* .card {
    margin-top: 3rem;
    width: 80px;
    height: 100px;
    padding:2px;
    display: flex;
    border-radius: 10px;
    overflow-inline:hidden ;
    background-color: #31197C;
    position: relative;
  }
  
  .card::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: 40px;
    background-color: orange;
    border-radius: 50%;
  }
  
  .card-content {
    padding: 10px;
    color: white;
  } */
.text-sequence{
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}
.sequence{
    background-color: #31197C;
    color:white;
    font-size:15px;
    font-family: Noto Sans;
    font-weight: 600;
    padding:1px 5px;
    width:23px;
    height:23px;
    border:2px solid #ff942c;
    border-radius: 50%;
}
.scroll-pricing {
    margin-top: 4.5rem;
    width: 330px;
    height: 430px;
    display: flex;
    padding: 0 6px 6px 6px;
    margin-right: 10px;
    border-radius: 10px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
}
.scroll-pricing-pp {
    margin-top: 4.5rem;
    width: 330px;
    height: 430px;
    display: flex;
    padding: 0 6px 6px 6px;
    margin-right: 10px;
    border-radius: 10px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
}

.scroll-pricing-y {
    margin-top: 9rem;
    width: 270px;
    height: 400px;
    display: flex;
    padding: 0 6px 6px 6px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #31197C;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
}

.free {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    margin-top: 15px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.free-y {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: white;
}

.monthly {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.monthly-y {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.8px;
    color: white;
}

.react-icon {
    color: green;
}

.react-icon-y {
    color: white;
}

.react-icon-red {
    color: red;
}

.pricing-inner-text {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #1E2022;
    white-space: nowrap;
    letter-spacing: 0.3px;
    /* text-align: left;
    justify-content: left; */
}

.pricing-inner-text-y {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.8px;
    color: white;
}

.money-color {
    color: #31197C;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.8px;
    position: absolute;
    bottom: 10%;
    left: 20%;
    right: 20%;
}

.money-color-y {
    color: white;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.8px;
    margin-top: 2.3rem;
}

.rupee {
    font-size: 28px;
}

/* .start{
    margin-left: 5.2rem;
} */
.get-btn {
    width: 9rem;
    height: 42px;
    color: white;
    border-radius: 12px;
    background: #FF942C;
}

.get-btn:hover {
    width: 9rem;
    height: 42px;
    color: white;
    border-radius: 12px;
    background: #FF942C;
}
.scroll-pricing-y:hover {
    transform: scale(1.12);
}

.scroll-pricing:hover {
    transform: scale(1.05);
    border-color: #31197C;
    border-width: 2px;

}

.mb {
    margin-bottom: 3.5rem;
}

/* .pricing-card{
    align-items:center;
    padding:1rem;
    height:400px;
    width:280px;
    z-index:1;
    border-color:grey;
    border-radius:10px;
    display:flex;
  } */

/* .pricing-card-lightgreen{
    align-items:center;
    padding:2rem, 3rem;
    height:360px;
    width:240px;
    background-color: white;
    border-color:grey;
    border-radius:10px;
  }
  .pricing-card-lightgreen:hover{
    transform: scale(1.05);
    z-index: 100;
  }
  .pricing-main{
    width:400px;
    height:500px;
}
.scroll-pricing{
    height:360px;
    width:200px;
    display:flex;
    padding:0 6px 6px 6px;
    margin-right:10px;
} */
.nebutech-analytics-llp{
  font-family: Noto Sans;
  font-size: 20px;
  color:black;  
}
.qr{
    width:60% !important;
    height:60% !important;
    margin-top: 1rem;
}
.scan{
    font-family: Noto Sans;
    font-size: 16px;
    color:black;    
    font-weight: 600;
}
.gppa{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.upload_transaction{
    width:auto;
    height:40px;
    float:right;
    border-radius: 6px;
    padding:5px;
    background-color: #31197C;
    color:white;
    font-size: 14px;
    font-family: Noto Sans;
}
.upload_transaction:hover{
    width:auto; 
    height:40px;
    float:right;
    border-radius: 6px;
    padding:5px;
    background-color: #31197C;
    color:white;
    font-size: 14px;
    font-family: Noto Sans;
}
.upload-trans{
    width:auto;
    height:40px;
    border-radius: 6px;
    padding:5px;
    background-color: #31197C;
    color:white;
    position: absolute;
    right:15px;
    bottom:4.5rem;
    font-size: 14px;
    font-family: Noto Sans;
}
.upload-trans:hover{
    width:auto;
    height:40px;
    border-radius: 6px;
    padding:5px;
    background-color: #31197C;
    color:white;
    position: absolute;
    right:15px;
    bottom:4.5rem;
    font-size: 14px;
    font-family: Noto Sans;
}
.upload-trans-center{
    width:auto;
    height:40px;
    border-radius: 6px;
    padding:5px;
    background-color: #31197C;
    color:white;
    position: absolute;
    left:50%;
    transform: translateX(-50%); 
    bottom:4.5rem;
    font-size: 14px;
    font-family: Noto Sans;
}
.upload-trans-center:hover{
    width:auto;
    height:40px;
    border-radius: 6px;
    padding:5px;
    background-color: #31197C;
    color:white;
    position: absolute;
    left:40%;
    bottom:4.5rem;
    font-size: 14px;
    font-family: Noto Sans;
}
.ip{
    margin-top: -7px;
}
.input-text-h{
    font-family: Noto sans;
    font-size: 14px;
}
.verification-pending{
    font-size: 16px;
    font-family: Noto Sans;  
    font-weight:600;
    color:#31197C; 
}