.sm-text {
    font-size: 11.5px;
    opacity: 0.4;
}

div {
    margin-top: 3px;
}

.back_arrow_ {
    top: 10vh;
    margin-left: 1.6rem;
    width: 9%;
    position: fixed;
}

.signup-text {
    position: absolute;
    left: 7.2%;
    right: 37.87%;
    top: 62.69%;
    /* top: 15.69%; */
    bottom: 73.4%;
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #2B2C43;
}
.add-prof-text {
    position: absolute;
    left: 8.5%;
    right: 37.87%;
    top: 62.69%;
    /* top: 15.69%; */
    bottom: 73.4%;
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #2B2C43;
}
._sceem {
    position: absolute;
    top: 0.5%;
    bottom: 73.4%;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #FF942C;
}

.form-signup {
    position: relative;
    top: 9.5rem;
}
.form-profile-register {
    position: relative;
    top: 4.5rem;
}
.login-credentials {
    border-radius: 6px;
    float: left;
    font-size: 15px;
    font-family: Noto Sans;
    color: #31197C;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
}

.box-form {
    border: 1px solid rgb(210, 207, 207);
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
}
.register_Btn{
    position: absolute;
    bottom:0.4rem;
    left:8%;
    right:8%;
    width: 85%;
    height: 3.4rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}
.register_Btn:hover {
    width: 85%;
    height: 3.4rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}
.signup-btn {
    width: 85%;
    height: 3.4rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.signup-btn:hover {
    width: 85%;
    height: 3.4rem;
    color: white;
    border-radius: 8px;
    background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.-Circle {
    position: relative;
    float: right;
    top: 24px;
    width: 40px;
    height: 62px;
}

.circle_Blue {
    position: absolute;
    top: 160px;
    width: 25px;
    height: 60px;
}

.topbar {
    z-index: 1;
    overflow: hidden;
    background-color: white;
    position: fixed;
    top: -3px;
    width: 100%;
    height: 160px;
}
.prof-topbar {
    z-index: 1;
    overflow: hidden;
    background-color: white;
    position: fixed;
    top: -3px;
    width: 100%;
    height: 100px;
}
.signup-terms {
    font-size: 12px;
    font-family: Roboto;
    font-weight: normal;
    float: left;
    margin-top: 10px;
    margin-left: 8%;
}

.signup-agree-text {
    position: absolute;
    margin-top: -2px;
    margin-left: 2px;
}

.signup-termslink-color {
    color: #FF942C;
}

#false {
    color: red;
}

#true {
    color: rgb(6, 155, 6);
}

#sme {
    color: rgb(6, 155, 6);
}

#pendingAuth {
    color: #FFBF00
}

#pendingReviewer {
    color: #FFBF00
}

#pendingSme {
    color: #FFBF00
}