.login-logo {
  position: fixed;
  width: 145px;
  height: 45px;
  top: 8px;
  left: 19px;
}
.profile-update-logo {
  position: fixed;
  width: 145px;
  height: 45px;
  top: 10px;
  left: 19px;
  z-index:10;
}
.top_bar {
  z-index: 1;
  overflow: hidden;
  background-color: white;
  position: fixed;
  top: -3px;
  width: 100%;
  height: 60px;
}

.top_interests_bar {
  z-index: 1;
  overflow: hidden;
  background-color: white;
  position: fixed;
  top: -3px;
  width: 100%;
  height: 130px;
}

.error {
  color: #db2269;
  font-size: 0.625em;
  text-align: left;
}

.errormsg {
  color: #db2269;
  font-size: 0.625em;
  float: center;
}

.valid {
  color: #31197C;
  font-size: 0.625em;
  float: left;
  margin-top: 4px;
  margin-bottom: 15px;
}

.-circle {
  position: relative;
  float: right;
  top: -15px;
  width: 40px;
  height: 62px;
}

.welcome-text {
  /* position: absolute;
left: 7.2%;
right: 37.87%;
top: 15.69%; */
  margin-top: 5.5rem;
  margin-left: 1.6rem;
  font-family: Noto Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 48px;
  color: #2B2C43;
}

.sceem {
  /* position: absolute;
left: 8%;
right: 67.73%;
top: 22.65%;
bottom: 68.23%; */
  margin-top: -1rem;
  margin-left: 1.6rem;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #FF942C;
}

.circle_blue {
  position: absolute;
  top: 190px;
  width: 25px;
  height: 60px;
}

.form-login {
  position: relative;
  top: 6rem;
}

.form-bg {
  background: #ECE9F3;
  border-radius: 8px;
  height: 3.4rem;
}

.form-bg:hover {
  background: #ECE9F3;
  border-radius: 8px;
  height: 3.4rem;
}

.form-bg-opp-date {
  margin-top: -6px;
  height: 2.4rem;
  font-size: 12px;
  width: 118%;
}

.form-bg-opp-date:hover {
  margin-top: -6px;
  height: 2.4rem;
  font-size: 12px;
  width: 118%;
}
.form-bg-adv-date {
  margin-top: -12px;
  height: 2rem;
  font-size: 12px;
  width: 124.5%;
}

.form-bg-adv-date:hover {
  margin-top: -12px;
  height: 2rem;
  font-size: 12px;
  width: 124.5%;
}
.form-bg-opp1{
  font-size: 12px;
}
.form-bg-opp1:hover {
  font-size: 12px;
}

.form-bg-opp {
  height: 2.4rem;
  font-size: 12px;
}
.form-bg-opp:hover {
  height: 2.4rem;
  font-size: 12px;
}

.form-edit {
  border-radius: 8px;
  height: 3.4rem;
}
.custom-datepicker-wrapper{
  padding:auto 2px;
}

._forgot-password {
  margin-right: 42px;
  font-size: 13px;
  font-weight: normal;
  float: right;
  color: #1C1C1C;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  opacity: 0.5;
}

.showPassword {
  position: sticky;
  float: right;
  margin-top: -2.8rem;
  padding: 5px;
  margin-right: 1rem;
}

._signup_txt {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #FF942C;
}

.login-btn {
  width: 80%;
  height: 3.4rem;
  color: white;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.login-btn:hover {
  width: 80%;
  height: 3.4rem;
  color: white;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.opportunity-btn {
  width: 90%;
  height: 3.4rem;
  color: white;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.opportunity-btn:hover {
  width: 90%;
  height: 3.4rem;
  color: white;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.flex {
  display: flex;
}

.google {
  width: 20%;
}

.facebook {
  width: 20%;
}

.btn-outline {
  border: 1px solid rgba(112, 112, 112, 0.05);
  background: #F9F9F9;
}

.alternateverify-text {
  margin-top: 7.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #FF942C;
}

.forgotImg {
  position: relative;
  top: 5rem;
  width: 150px;
  height: 120px;
}

.forgot-text {
  margin-top: 7.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #2B2C43;
}

.numbers-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #2B2C43;
}

.forgot-text-sm {
  margin-top: 1rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #2B2C43;
  opacity: 0.3;
}

.forgot-email {
  margin-top: 3rem;
}

.try {
  color: #4099FF;
}

.verify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.digit-width {
  width: 40px;
  height: 40px;
  color: black;
  border-radius: 6px;
  height: 3.2rem;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: bold;
  font-size: 25px;
}
.inp-border{
  text-align: center;
  border:1px solid #FF942C;
}
.inp-border:focus{
border:1px solid #FF942C;
}
.ip-border{
  border:1px solid grey;
  }
  .i-border{
    border:1px solid red;
    }
.otp-width {
  width: 23px;
  height: 23px;
  color: black;
  border-radius: 6px;
  height: 1.8rem;
  margin-left: 1px;
  margin-right: 1px;
  font-weight: 700;
  font-size: 16px;
  padding: 0 2px 3px 2px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sort-digit-width {
  width: 40px;
  height: 40px;
  color: black;
  border-radius: 6px;
  height: 3.2rem;
  margin-left: 1px;
  margin-right: 1px;
  font-weight: 500;
  font-size: 18px;
}

.newpassword {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
}

.newpassword-left {
  margin-left: 2.2rem;
}

.profile-icon {
  margin-top: 160px;
  width: 130px;
  height: 130px;
  margin-left: 1rem;
}

.profile-heading {
  position: absolute;
  left: 7.2%;
  right: 37.87%;
  top: 15.69%;
  font-family: Noto Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #2B2C43;
}

.profile-upload {
  float: right;
  width: 8%;
  margin-top: -2.5rem;
  margin-right: 30%;
  z-index: 1;
}

.form-profile {
  position: relative;
  top: 10px;
}

.circle_yellow {
  float: right;
  margin-top: 12px;
  width: 25px;
  height: 60px;
}

.next {
  margin-top: 7rem;
}

.center {
  justify-content: center;
  align-items: center;
  margin-left: 2.4rem;
}

.save-changes-btn {
  width: 95%;
  height: 3.2rem;
  color: white;
  margin-top: 25px;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

.save-changes-btn:hover {
  width: 95%;
  height: 3.2rem;
  color: white;
  margin-top: 25px;
  border-radius: 8px;
  background: linear-gradient(196.07deg, rgba(49, 25, 124, 0.76) -5.47%, #31197C 71.88%);
}

/* @keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.login-width {
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInLeft;
} */