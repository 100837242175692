.react-horizontal-scrolling-menu--wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .react-horizontal-scrolling-menu--scroll-container {
    flex-basis: 100%;
    flex-shrink: 0;
  }
  
  /* Change position of container so arrows will be at top/bottom */
  .react-horizontal-scrolling-menu--scroll-container {
    order: -1;
  }