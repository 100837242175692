
    .bottom-icon {
      width: 70px;
      position: absolute;
      bottom: 20px;
      right: 0;
    }
  
    .network {
      margin-top: 26%;
      font-family: Noto sans;
    }
  
    .opportunity {
      margin-top: 24%;
    }
  
    .advertisement {
      margin-top: 15%;
    }
  
    .articles {
      margin-top: 25%;
    }
  
    .polls {
      margin-top: 25%;
    }
    .polls-adv {
      margin-top: 16%;
    }
    .chain-text {
      display: flex;
      margin-top: 0px;
      font-size: 15px;
      color: #31197C;
      font-weight: 600;
    }
  
    .service-team::before {
      content: '';
      display: block;
      height: 30px;
      margin-top: -30px;
      visibility: hidden;
    }
  
    .service::before {
      content: '';
      display: block;
      height: 20px;
      margin-top: -20px;
      visibility: hidden;
    }
  
    .service-commit::before {
      content: '';
      display: block;
      height: 20px;
      margin-top: -20px;
      visibility: hidden;
    }
  
    /* .service-home::before {
      content: '';
      display: block;
      height: 85px;
      margin-top: 85px;
      visibility: hidden;
    } */
  
    .service-about::before {
      content: '';
      display: block;
      height: 40px;
      margin-top: -40px;
      visibility: hidden;
    }
  
    .service-offerings::before {
      content: '';
      display: block;
      height: 60px;
      margin-top: -60px;
      visibility: hidden;
    }
  
    .service_signup::before {
      content: '';
      display: block;
      height: 100px;
      margin-top: 100px;
      visibility: hidden;
    }
  
    .home-image-lan {
      width: 100%;
      height: 25vh;
    }
  
    .l-page-top {
      /* position: absolute;
      top: 4%;
      right: 1rem; */
      margin: 5px 15px;
      color: #31197C;
      font-size: 25px;
      font-weight: bold;
      font-family: Noto Sans;
    }
  
    .l-page-text {
      /* position: absolute;
      top: 17%;
      z-index: 1;
      right: 1rem;
      left: 35%; */
      color: black;
      text-align: left;
      margin: 5px 18px;
      font-size: 18px;
      font-weight:600;
      font-family: Noto Sans;
    }
  
    .about-sceem {
      margin-top: 2rem;
      width: 70%;
      margin-left: 4rem;
      margin-right: 4rem;
    }
  
    .about-sceem-top {
      margin-top: 0.5rem;
    }
  
    .about-sceem-heading {
      margin-top: 2rem;
      font-size: 20px;
      font-weight: 600;
      font-family: Noto Sans;
      margin-left: 2rem;
      color: #31197C;
    }
  
    .about-sceem-textsm {
      margin-top: 0.5rem;
      font-size: 14px;
      font-weight: 400;
      font-family: Noto Sans;
      margin-left: 2rem;
      color: black;
    }
  
    .vision-bg {
      background-color: #31197C;
      height: 50%;
      color: white;
    }
  
    .our-vision {
      position: absolute;
      margin-top: -3px;
      width: 60%;
      background-color: #31197C;
      left: 21%;
      right: 20%;
      text-align: center;
      color: white;
      font-size: 25px;
      font-weight: 600;
      font-family: Noto Sans;
    }
  
    .our-topics {
      position: absolute;
      margin-top: -3px;
      width: 56%;
      background-color: #31197C;
      left: 22%;
      right: 20%;
      text-align: center;
      color: white;
      font-size: 25px;
      font-weight: 600;
      font-family: Noto Sans;
    }
  
    .sceem-4es {
      width: 100%;
      height: 100%;
    }
    .topics{
        font-family: Noto Sans;
        font-size: 12px;  
      }
    .vision {
      border: 2px solid #FF942C;
      border-radius: 20px;
      padding: 1.5rem;
      width: 80%;
      margin: 1rem auto;
    }
  
    /* .topic-allicons {
      display: grid;
      grid-template-columns: repeat(8, auto);
      grid-gap: 5px;
      background-color: transparent;
      margin-left: 0%;
      margin-right: 0.8%;
    } */
    .topic-allicons {
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-gap: 2px;
      background-color: transparent;
      margin-left: 0%;
      margin-right: 0%;
    }
    .games-Text{
      text-align: center;
      justify-content: center;
      align-items: center;
      font-family: Noto Sans;
      font-size: 13px;
      font-weight: 600;
    }
    .topic-each-icon {
      margin-top: 4px;
      width: 32px;
    }
    .topic-each-icon-logis{
      width: 30px;
      margin-top: 6px;
    }
    .topic-each-icon-1 {
      width: 20px;
    }
    .topic-each-icon-2 {
      width: 25px;
    }
    .offerings {
      text-align: center;
      color: #31197C;
      font-size: 25px;
      font-weight: 600;
      font-family: Noto Sans;
    }
    .offerings-Features {
        text-align: center;
        color: #31197C;
        font-size: 20px;
        font-weight: 600;
        font-family: Noto Sans;
      }
    .features-vh {
      width: 100%;
      height: 100%;
    }
  
    .offerings-allicons {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 15px;
      padding-left: 0.1rem;
      background-color: transparent;
    }
  
    .offerings-allicons {
      width: 60%;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 1.2rem;
      margin: 0.8rem auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
    }
    .offerings-allicons-four {
      width: 50%;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 0rem;
      margin: 0.5rem auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
    }
    .offerings-allicons-two {
      width: 70%;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 2rem;
      margin: 1rem auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
    }
    .offer-text {
      font-size: 13px;
      font-weight: 600;
      font-family: Noto Sans;
      text-align: center;
      margin-left: 7px;
    }
    .offer-each-icon {
      width: 55px;
      margin:auto;
      display: inline;
      margin-left: 10px;
    }
  
    .team-bg {
      background-color: #31197C;
      height: 50%;
      color: white;
    }
  
    .team-heading {
      text-align: center;
      color: white;
      font-size: 25px;
      font-weight: 600;
      font-family: Noto Sans;
    }
  
    .team-allicons {
      width: 96%;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 0.75rem;
      margin: 1rem auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
    }
  
    .partner-allicons {
      width: 95%;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 0.6rem;
      margin: 1rem auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
    }
  
    .team-allicons-three {
      width: 96%;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 0.8rem;
      margin: 1rem auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
    }
  
    .team-each-icon {
      width: 75px;
    }
  
    .partner-icon {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  
    .team-each-icon-new {
      margin-top: -10px;
      width: 75px;
    }
  
    .team-role {
      /* display: column;
      margin-left: auto;
      margin-right: auto; */
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    .team-name {
      margin-top: 3px;
      color: #FF942C;
      font-size: 13px;
      font-weight: 500;
      font-family: Noto Sans;
    }
  
    .designation {
      color: white;
      font-size: 11px;
      font-weight: normal;
      font-family: Noto Sans;
    }
  
    .connect-vh {
      width: 100%;
      height: 100%;
    }
  
    .contact-img {
      width: 100%;
      height: auto;
      margin-top: 0;
      float: right !important;
    }
  
    .connect {
      margin-left: 1rem;
      margin-top: 1rem;
      font-size: 18px;
      color: #31197C;
      font-weight: 600;
      font-family: Noto Sans;
    }
  
    .connect-text {
      margin-left: 1rem;
      margin-top: 0.3rem;
      font-size: 22px;
      color: #31197C;
      font-weight: 600;
      font-family: Noto Sans;
    }
  
    .pricing-ml {
      margin-left: 4.5%;
      margin-right: 4.5%;
    }
  
    .ml-p {
      margin-left: 15px;
      margin-right: 15px;
    }
  
    .stats {
      margin-left: 0px;
    }
  
    .connect-gmail {
      margin-left: 1rem;
      font-size: 18px;
      color: #31197C;
      font-weight: 600;
      font-family: Noto Sans;
    }
  
    .l-app-background {
      width: 100%;
      height: auto;
    }
  
    .l-app-border {
      position: absolute;
      left: 10%;
      right: 10%;
      align-items: center;
      justify-content: center;
      margin-top: -31.5%;
      border: 2px solid white;
      border-radius: 15px;
      width: 80%;
      height: 15.5%;
    }
  
    .appstore {
      width: 18%;
    }
  
    .footer_bg {
      width: 100%;
      height: 50%;
      background-color: #282828
    }
  
    .sceem-logo {
      width: 25%;
      margin-top: 1rem;
      margin-left: 1.5rem;
    }
  
    .iso {
      width: 24%;
      margin-top: -2px;
      margin-left: 1.8rem;
    }
  
    .links {
      margin-top: 0.5rem;
      margin-left: 1.5rem;
      color: white;
      font-size: 15px;
      font-weight: 700;
      font-family: Noto Sans;
    }
  
    .links-sm {
      color: white;
      margin-left: 1.5rem;
      font-size: 14px;
      font-weight: 400;
      font-family: Noto Sans;
      text-decoration: none !important;
    }
  
    .powered-by {
      position: relative;
      margin-top: -2.8rem;
      left: 86%;
      width: 12%;
    }
  
    .l-download-text {
      margin-top: -5.4rem;
      font-size: 15px;
      color: white;
      font-weight: 600;
      font-family: Noto Sans;
    }
  
    .l-download-text-sm {
      margin-top: 0.2rem;
      font-size: 12px;
      color: white;
      font-weight: 400;
      font-family: Noto Sans;
    }
  
    .l-googleplay {
      width: 23%;
    }
  
    .l-powered-by-nebutech {
      position: relative;
      margin-top: 2.5rem;
      left: 2%;
      width: 18%;
    }
  
    .l-sceem-logo-download {
      width: 10%;
      margin-top: 0.4rem;
      margin-left: 0.4rem;
    }
  
    .background-lp {
      width: 100%;
      height: 26%;
      font-family: Noto Sans;
    }
  
    .app-border-lp {
      width: 95%;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 0.5rem;
      margin: auto;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      border: 1.5px solid #31197C;
      border-radius: 10px;
      height: 100%;
    }
  
    .sceem-logo-download-lp {
      width: 80%;
      margin-left: 4%;
      /* margin-top: 0.4rem;
      margin-left: 0.4rem; */
    }
  
    .powered-by-nebutech-lp {
      /* margin-top: 0.5rem;
      width: 18%;
      margin-left: 80%;
      margin-bottom: 6px; */
      width: 85%;
      margin-right: 10px;
    }
  
    .download-text-lp {
      margin-top: -0.5rem;
      font-size: 10px;
      color: black;
      font-weight: 400;
      font-family: Noto Sans !important;
    }
  
    .googleplay-lp {
      margin-top: 3px;
      width: 23%;
    }
  
    .flip-box {
      background-color: transparent;
      width:80px;
      height: 80px;
      perspective: 1000px;
      border-radius: 50%;
    }
  
    .flip-box-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }
  
    .flip-box:hover .flip-box-inner {
      transform: rotateY(-180deg);
    }
  
    .flip-box-front,
    .flip-box-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  
    .flip-box-front {
      background-color: #fff;
      color: black;
      width: 80px;
      height: 80px;
    }
  
    .offer-back-heading {
      font-size: 11px;
    }
  
    .flip-box-back {
      background-color: white;
      color: black;
      border: 3px solid #FF942C;
      font-size: 9px;
      font-family: Noto Sans;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      transform: rotateY(180deg);
    }
  
    .sign-b-agree {
      margin-top: 1rem;
    }
  
    /* .enable-center {
      display: grid;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
      width: 100%;
      border: none;
      min-height: 100%;
    } */
  
    .signup-text {
      font-family: Noto Sans !important;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #2B2C43;
      margin-left: 8.5%;
      /* margin-top: 5rem; */
    }

    .knowmore-left {
      margin-left: 8%;
    }
  
    .know-more-lr {
      align-items: left;
      margin-left: 1.2rem;
      margin-right: 0.5rem;
      font-family: Noto Sans !important;
    }
  
    .rat-bg {
      position: absolute;
      left: 30%;
    }
    .article-ratings-text {
      font-size: 16px;
      font-family: Noto Sans;
      color: #31197C;
      font-weight: 600;
    }
    .listen-T{
      margin-top:0px;
    }
    .latest-ratings {
      font-size: 18px;
      font-family: Noto Sans;
      color: #31197C;
      font-weight: 600;
    }
    .latest-ratings-text-sm {
      font-size: 17px;
      font-family: Noto Sans;
      color: #31197C;
      font-weight: 600;
    }
    .feddback_card {
      width: 100%;
    }
    .feddback_card-art {
      width: 100%;
      margin-left: 10px;
    }
  
    .feed-ratings {
      padding-left: 10%;
      width: 100%;
      max-width: 100%;
      text-align: left;
      font-family: Noto Sans;
    }
    .feed-ratings-art {
      padding-left: 7%;
      width: 100%;
      max-width: 100%;
      text-align: left;
      font-family: Noto Sans;
    }
  .rating-v-align{
    margin-top: 2.2rem;
  }
  .rating-v-align-art{
    margin-top: 1.9rem;
  }
  .avgRatingCount-art {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    top:2rem;
    left: 17%;
  }
  .milestone-img-art{
    width:60%;
  }
    .avgRatingCount {
      position: absolute;
      font-size: 20px;
      font-weight: 600;
      top: 2.3rem;
      left: 20%;
    }
    .desc{
      font-family: Noto Sans;
      font-size:16px;
      height:100px
    }
    .flip-lr{
      margin:auto;
    }
    .flip-lr-2{
      margin:auto
    }
    .articles_Published {
        border-radius: 10px;
        height: 125px;
        width: 26.7vw;
        margin-left: 3.8vw;
        padding: 12px 0 5px 0;
        border: 2px solid rgb(228, 225, 225);
      }
    
      .bold-text {
        font-weight: 700;
        font-size: 22px;
        color: #FF942C;
        font-family: Noto Sans;
      }
    
      .stats-head {
        margin-left: 0.2rem;
        color: #FF942C;
        margin-top: 0.7rem;
        font-family: Noto Sans;
        font-weight: 600;
      }
      .-head {
        margin-left: 0.2rem;
        margin-top: -0.3rem;
        color: #31197C;
        z-index:1000 !important;
        font-size: 18px;
        font-family: Noto Sans;
        font-weight: 600;
      }
      .campaigns_polls {
        border-radius: 10px;
        height: 90px;
        width: 26.5vw;
        padding: 12px 0 5px 0;
        margin-left: 4vw;
        border: 2px solid rgb(228, 225, 225);
      }
      .eminent_e {
        border-radius: 10px;
        height: 90px;
        width: 57.5vw;
        padding: 12px 0 5px 0;
        margin-left:12px;
        border: 2px solid rgb(228, 225, 225);
      }
      .text-in_about {
        font-weight: 600;
        font-size: 13px;
        margin-left: auto;
        margin-right: auto;
        font-family: Noto Sans;
      }
      .visit-btn {
        border: #31197C;
        background: #31197C !important;
        width: auto;
        height: 3rem;
        padding: auto;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        color: white;
        display: flex;
        white-space: nowrap;
        text-align: center;
      }
      .visit-btn:hover {
        border: #31197C ;
        background: #31197C !important;;
        width: auto;
        height: 3rem;
        padding: auto;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        color: white;
        display: flex;
        white-space: nowrap;
        text-align: center;
      }