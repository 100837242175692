body {
  margin: 0;
  font-family: 'Noto Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
}

.bell-icon {
  width: 30px;
  margin-top: 25px;
  margin-right: 8px;
  float: right;
  color: #31197C;
}

.bell-icon-empty {
  width: 30px;
  margin-top: 25px;
  margin-right: 8px;
  float: right;
  color: #31197C;
}

.no-connection {
  position: absolute;
  top: 45%;
  left: 10%;
  right: 10%;
}

.sceem-app {
  text-align: left;
  font-family: Noto Sans;
}

.link-color {
  color: black;
}

.nebutecH {
  position: absolute;
  bottom: 10px;
  right: 11%;
  width: 15%;
}

.nebutecH-login-img {
  width: 80%;
}

.service::before {
  content: '';
  display: block;
  margin-top: -70%;
  visibility: hidden;
}

.download-pdf {
  float: right;
  margin-right: 15px;
  color: black;
}

.home-bg {
  /* background-image: linear-gradient(to right bottom, #eadbf3, #e2ddf6, #dadff7, #d3e0f7, #cde2f5, #c5def3, #bcd9f0, #b3d5ee, #a9c9ec, #a4bce9, #a4aee4, #a99fdb);
  */
  background-image: linear-gradient(to right bottom, #efe2ef, #e9e2f2, #e1e3f5, #d9e4f6, #d1e5f5, #d1e5f5, #d1e5f5, #d1e5f5, #d9e4f6, #e1e3f5, #e9e2f2, #efe2ef);
  height: calc(92vh);
  overflow-y: auto;
}

.enable-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  border: none;
  min-height: calc(85vh);
}

.screen-top-gap-home {
  margin-top: 3.3vh;
}

.screen-top-gap {
  margin-top: 7vh;
}

.screen-topgap {
  margin-top: 7vh;
}
.screen-topgap-sme {
  margin-top: 5.2vh;
}
.screen-topgap-term {
  margin-top: 6.5vh;
}
.leaderboard-topgap {
  margin-top: 6vh;
}

.gap {
  margin-top: 5vh;
}

.text-black {
  color: black;
}

/* (A) WRONG ORIENTATION - SHOW MESSAGE HIDE CONTENT */
@media only screen and (orientation:landscape) {
  #turn {
    display: block;
  }

  #container {
    display: none;
  }
}

/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media only screen and (orientation:portrait) {
  #turn {
    display: none;
  }

  #container {
    display: block;
  }
}

/* .circular {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ccc;
}
.circular span {
  display: inline-block;
  transform-origin: center;
  transform: rotate(0deg);
  font-size: 24px;
  font-weight: bold;
}
.circular span:nth-child(1) {
  transform: rotate(60deg);
}
.circular span:nth-child(2) {
  transform: rotate(120deg);
}
.circular span:nth-child(3) {
  transform: rotate(180deg);
}
.circular span:nth-child(4) {
  transform: rotate(240deg);
}
.circular span:nth-child(5) {
  transform: rotate(300deg);
}
.circular span:nth-child(6) {
  transform: rotate(360deg);
} */
.puzzle1 {
  display: inline-block;
  padding: 20px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid red;
}

#circular-Text {
  position: relative;
  background: #fff;
  color: black;
  padding: 20px;
  font-size: 25px;
  font-weight: 600;
  font-family: "Noto Sans";
}

#circular-Text span {
  position: absolute;
  transform-origin: top left;
}

/* .circular-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width:100%;
}

.circular-text {
  font-size: 24px;
  color: #000;
  font-weight:600;
  text-align: center;
  position: relative;
}

.circular-text span {
  position: absolute;
  display: block;
} */
.wrapper {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

.wrapper h4 {
  font-family: 'Noto Sans';
  font-size: 22px;
  font-weight: 600;
  color: black;
  text-align: center;
  position: absolute;
  left: 50%;
  top: calc(48%);
  /* -webkit-transform: translate(-50%, calc(-50% - 64px));
  -ms-transform: translate(-50%, calc(-50% - 64px));
  transform: translate(-50%, calc(-50% - 64px)); */
}

.wrapper h4 span {
  position: absolute;
}

/* #canvas{
      position: absolute;
      left: -32%;
      bottom: -40%;
      overflow: hidden !important;

} */
.text-C {
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 600;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(5, 2rem);
  column-gap: 5px;
  align-items: center;
  justify-content: center;
}

.vowel-btn {
  width: auto;
  height: auto;
  padding: 1px;
  padding-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  background-color: #31197C;
  color: white;
  border-radius: 10px;
}

.spin-game-image {
  position: relative;
  top: 3rem;
  width: 125px;
  height: 120px;
}

.spin-game-text {
  margin-top: 3.5rem;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #2B2C43;
}

.score {
  font-family: "Noto Sans";
  font-weight: 600;
  font-size: 18px;
  color: black;
}

.group-terms {
  font-size: 12px;
  font-family: Noto Sans;
  font-weight: normal;
  float: left;
  margin-top: 10px;
  margin-left: 2%;
}

.group-agree-text {
  position: absolute;
  margin-top: -2px;
  margin-left: 2px;
}

.Terms-Txt {
  font-size: 12px;
  font-family: Noto Sans;
  font-weight: normal;
}

.Terms-Txt-Color {
  font-size: 12px;
  font-family: Noto Sans;
  font-weight: Bold;
  color: #FF942C !important;
}

.install-popup {
  position: absolute;
  top: 3rem;
  left: 10%;
  color: black;
  padding: 0.5rem 0.5rem 0.8rem 0.5rem;
  background-color: white;
  opacity: 95;
  width: 80%;
  border-radius: 0.5rem;
  border: 1px solid lightgray;
  z-index: 150;
  box-shadow: 1px 1px 3px #a8a8a8,
    -2px -2px 4px #ffffff;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.insBtn {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  align-items: center;
}

.inner-insBtn {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  align-items: center;
}

.install-text {
  text-decoration: underline;
  color: rgb(97, 97, 249);
  text-underline-offset: 1px;
  margin-right: 2px;
  margin-bottom: -2px;
}

/* .bg-color {
  background: #FF942C !important;
  width: 100%;
} */
/* @keyframes background-color {
  from {
    background-color: #FF942C !important;
  }
  to {
    background-color: #FF942C !important;
  }
} */
/* .bar {
  height: 10px;
  width: 100%;
  background-color: gray;
} 
  .in {
    height: 10px;
    width: 100%;
    animation: fill 10s linear 1;
    background-color: pink !important;
  } */


@keyframes fill {
  0% {
    width: 0%;
    background-color: #FF942C !important;
  }
  100% {
    width: 100%;
    background-color: #FF942C !important;
  }
}
.bg-color{
  margin-top:-0.1px;
  background-color: #FF942C !important;
  animation: fill 10s linear 1;
  height: 100%;
}
.sec-color {
  width: 70px;
  height: 8px;
  background-color: gray;
}
.sec-display {
  display: flex;
  grid-auto-rows: 4;
  gap: 5vw;
  align-items: center;
  text-align: center;
  justify-content: center;
}
/* .sec-display::before {
  background-color: gray;
  transition: width 3s linear;
  z-index: -1;
} */
.self-text {
  font-size: 18px;
  font-family: Noto Sans;
  color: #31197C;
  font-weight: 600;
}

.j-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 1px 15px;
}

.profile-Auth-img {
  margin-top: -10px;
  width: 130px;
}

.profile_Card_ {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  padding: 5px 10px;
  border: none;
  box-shadow: 1px 1px 3px #a8a8a8,
    -2px -2px 4px #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.profile_Card_sme-topics {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  height: auto;
  padding: 5px 4px;
  border: none;
  box-shadow: 1px 1px 3px #a8a8a8,
    -2px -2px 4px #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.ei {
  position: absolute;
  margin-left: 0.85rem;
  margin-right: 0.85rem;
  left: 0;
  right: 0;
  bottom: 4rem;
  text-align: center;
  border: 1px solid #31197C;
  border-radius: 10px;
}
.poll-result-card-post {
  padding: 0.5rem 1rem 1rem 0;
  height: auto;
  width: 94%;
  background-color:#ebfcfc;  
  border-radius: 20px;
  margin: 0 auto;
}
.art-poll-text-h{
  font-size: 18px;
  font-family: Noto Sans;
  font-weight: 600;
  color:#31197c;
}
.quiz-card-border{
  width:97% !important;
  border:1px solid gray;
  background-color: #fff2e6;
  border-radius: 12px;
  padding-bottom: 6px;
}
.quiz-card-border-p{
  border:1px solid gray;
  background-color: #fff2e6;
  border-radius: 12px;
  padding-bottom: 6px;
  width:100%;
  padding:4px 10px 4px 0px;
}
.version{
  font-size:12px;
  position: fixed;
  right: 10px;
  bottom:-3px;
}
.update-popup {
  position: fixed;
  bottom: 2rem;
  left: 10%;
  color: black;
  padding: 0.5rem 0.5rem 0.8rem 0.5rem;
  background: #ECE9F3;
  opacity: 95;
  width: 80%;
  border-radius: 0.5rem;
  border: 1px solid lightgray;
  z-index: 150;
  box-shadow: 1px 1px 3px #a8a8a8,
    -2px -2px 4px #ffffff;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.updBtn {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  align-items: center;
}

.inner-updBtn {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  align-items: center;
}

.upd-text {
  text-decoration: underline;
  color: rgb(97, 97, 249);
  text-underline-offset: 1px;
  margin-right: 2px;
  margin-bottom: -2px;
}
