.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .square {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 50px;
    height: 52px;
  }
  
  .circle-c {
    position: relative;
    float: right;
    top: 110px;
    width: 40px;
    height: 60px;
  }
  
  .circle-yellow {
    position: relative;
    float: left;
    top: 180px;
    width: 25px;
    height: 60px;
  }
  
  /* .logo {
    position: absolute;
    top: 190px;
    width: 200px;
    height: 186px;
  } */
  
  .logo-gif {
    /* position: absolute;
    top: 180px;
    width: 70%;
    height: 22%;
    left: 18.5%;
    right: 26%;
    background-color: #ffffff;
    animation: mymove ;
    animation-duration: 4s;
    transition: 3s ease-in-out; */
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  @keyframes mymove {
    from {top: 180px;}
    to {top: 180px;}
  }
  
  .circle-blue {
    position: relative;
    top: 410px;
    left: -27px;
    width: 25px;
    height: 60px;
  }
  
  .nebutech {
    position: relative;
    top: 450px;
    left:7%;
    right:7%;
    width: 207px;
    height: 64px;
  }
  .isocertified {
    position: relative;
    margin: auto;
    display: block;
    top: 450px;
    width:75px;
  }
  
  .circle-reverse-yellow {
    position: relative;
    float: right;
    top: 450px;
    width: 25px;
    height: 60px;
    right: -3px;
  }
  .square-new {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 50px;
    height: 50px;
  }