.followers-Text {
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin: 8px auto 8px auto;
    color: #FF942C;
}
.followers-Text_ {
    font-family: Noto Sans !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 8px auto 8px auto;
    color: #FF942C;
}
.profile-active {
    color: #FF942C !important;
    border-bottom: 2px solid #FF942C;
}

.rec-profile {
    width: 50px;
    margin-top: -3px;
}

.plus {
    cursor: pointer;
    color: #31197C;
    margin-top: 7px;
    margin-left: 10px;
}

.rec-save-btn {
    display: block;
    border: 2px solid #31197C;
    ;
    background-color: white;
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    color: #31197C;
    width: 6rem;
    height: 36px;
    float: right;
    margin-top: 2px;
}

.rec-name {
    font-size: 14px;
    font-weight: 600;
    margin-left: -10px;
}

.rec-follow {
    font-size: 12px;
    font-weight: 500;
    color: grey;
    margin-left: -10px;
}

.rec-left {
    margin-left: 27%;
}
.rec-left1 {
    margin-left: 15%;
}
.recommended-fixed {
    background-color: white;
    position: fixed;
    width: 100%;
    top: 2.5rem;
    margin-top: 15px;
    height: 55px;
    z-index: 100;
}
.newMembers-fixed {
    background-color: white;
    position: fixed;
    width: 100%;
    top: 2.5rem;
    margin-top: 12px;
    height: 55px;
    z-index: 100;
}