
.speech-bubble{
    --arrow-w:0.3em;
    --arrow-h:1em;
    --bubble-corners:0;
    --w:17em;
    --border-size:2px;
    --text-color:black;
    --bubble-color:white;
    --border-color:black;
  }
  .speech-bubble{
    text-align:center;
    justify-content: center;
    font-family:"Raleway",sans-serif;
    font-size:14px;
    font-weight:700;
    color:var(--text-color);
    background:var(--bubble-color);
    padding:1.4em;
    position:relative;
    border-radius:var(--bubble-corners);
    width:var(--w);
    height:5rem;
    max-height:6rem;
    box-shadow:0 0 0 var(--border-size) var(--border-color);
  }
  
  .speech-bubble.round{
    --bubble-corners:1em;
  }
  
  .speech-bubble.circle{
    --bubble-corners:50%;
    padding:1.4em;
  }
  
  .speech-bubble:before,.speech-bubble:after{
    content:"";
    position:absolute;
    bottom:calc(-1 * var(--arrow-h) * 2 + 0.5px);
    left:50%;
    border-style:solid;
    border-width:var(--arrow-h) var(--arrow-w);
    border-color:var(--bubble-color) transparent transparent;
  }
  
  .speech-bubble:before{
    border-width:calc(var(--arrow-h) + var(--border-size)) calc(var(--arrow-w) + var(--border-size));
    border-color:var(--border-color) transparent transparent;
    bottom:calc(-1 * var(--arrow-h) * 2 - 2.5*var(--border-size)); 
    left:calc(50% - var(--border-size));
  }
  
  
  
  
  .speech-bubble.l:after{
    border-color:transparent var(--bubble-color) transparent transparent;
    bottom:auto;
    border-width:var(--arrow-w) var(--arrow-h);
    top:calc(50% - var(--arrow-w));
    left:calc(-1 * var(--arrow-h) * 2 + 0.5px);
  }
  
  .speech-bubble.l:before{
    border-width:calc(var(--arrow-w) + var(--border-size)) calc(var(--arrow-h) + var(--border-size));
    border-color:transparent var(--border-color) transparent transparent;
    bottom:auto;  
    top:calc(50% - var(--arrow-w) - var(--border-size));
    left:calc(-1 * var(--arrow-h) * 2 - 2.5*var(--border-size)); 
  }
  .speech-bubble.r:after{
    border-color:transparent transparent transparent var(--bubble-color);
    bottom:auto;
    left:auto;
    border-width:var(--arrow-w) var(--arrow-h);
    top:calc(50% - var(--arrow-w));
    right:calc(-1 * var(--arrow-h) * 2 + 0.5px);
  }
  
  .speech-bubble.r:before{
    border-width:calc(var(--arrow-w) + var(--border-size)) calc(var(--arrow-h) + var(--border-size));
    border-color:transparent transparent transparent var(--border-color);
    bottom:auto;
    left:auto;
    top:calc(50% - var(--arrow-w) - var(--border-size));
    right:calc(-1 * var(--arrow-h) * 2 - 2.5*var(--border-size)); 
  }
  
  