.adv-card {
  margin: 0px 0.9rem;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 5px;
  overflow: hidden;
}

.adv-detail-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  min-height: 45px;
  padding: 2px;
}

.adv-image-home-detail {
  max-width: 50px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}

.bannerImg-d {
  width: 150px;
  height: 60px;
}

.arrow-adv {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  padding: 2px;
  z-index: 1;
  position: relative;
}

.tagline-title_ {
  font-family: Raleway, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FF942C;
  margin-top: 10px !important;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  text-align: left;
}

.latest-art-card-d {
  padding: 5px;
  width: 91%;
  margin-left: 4.5%;
  margin-right: 1%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
  margin-top: -5px;
}

.article-sm-img {
  margin-top: 0.5rem;
  width: 95%;
}

.favbits-title-latest-d {
  font-size: 13px;
  color: black;
  text-align: left;
  font-family: Noto Sans;
  font-weight: 600;
}

.favbits-author-d {
  margin-top: 4px;
  width: 35px;
  margin-bottom: 4px;
}

.latest-dd {
  padding: 0.5rem;
  height: auto;
  background: #fcecdd;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0 1rem;
  border: none;
  width: 91%;
}

.topic-d {
  font-family: Noto Sans;
  font-size: 12px;
}

.table-content {
  font-weight: 600;
  font-family: Noto sans;
  color: #ff942c;
  font-size: 16px;
}

.poll-answer-card-post-d {
  align-items: center;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  height: auto;
  width: 94%;
  border-radius: 20px;
  margin: 15px auto;
}

.poll-question-card-post-d {
  padding: 0.5rem;
  height: auto;
  background: #fcecdd;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: none;
  font-weight: 600;
  /* width: 100%; */
  width: 100%;
}

.poll-answer-card-post-d {
  padding: 0.5rem;
  height: auto;
  background-color: #ffffff;
  /* background: #f6f0fa; */
  font-family: Raleway, sans-serif !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: -4px;
  border: none;
  width: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
.coin-spin {
  animation: spin 2s linear;
}

.poll-result-card-post-d {
  padding: 0.5rem;
  height: auto;
  font-family: Raleway, sans-serif !important;
  background-color: #ffffff;
  /* background-color: #f6f0fa; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: -1px;
  border: none;
  width: 100%;
}

.container_class-d {
  width: 100%;
  height: 7vh;
  background-color: white;
  border-radius: 10px;
  font-family: Raleway, sans-serif !important;
  margin: 10px 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid lightgrey;
}

.polloptions-card-post-d {
  margin-top: 0.5rem;
  width: 100%;
  font-family: Raleway, sans-serif !important;
  height: 50px;
  display: flex;
  padding: 2px 5px;
  border-radius: 10px;
  margin: 5px 10px;
  border: 1px solid lightgrey;
}

.selectedd {
  border: 2px solid #DB6C00;
}

.up-p {
  padding: 0.5rem 1rem;
  background-color: #31197C;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.up-p:hover {
  background-color: #31197C;
}

.article-h1::before {
  content: '';
  display: block;
  height: 21rem;
  margin-top: -21rem;
  visibility: hidden;
}

.art-d_::before {
  content: '';
  display: block;
  height: 3.7rem;
  margin-top: -3.7rem;
  visibility: hidden;
}

.no-art-d_::before {
  content: '';
  display: block;
  height: -9.8rem;
  margin-top: -9.8rem;
  visibility: hidden;
}

.article-data-h1::before {
  content: '';
  display: block;
  height: 10rem;
  margin-top: -10rem;
  visibility: hidden;
}

.poll-text-d {
  font-size: 15px;
  font-family: Raleway, sans-serif !important;
  align-items: flex-start;
  float: left;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
  animation-duration: 3s;
  /* animation-name: slidein; */
}

@keyframes slidein {
  from {
    margin-left: 50%;
  }

  to {
    margin-left: 0%;
  }
}

.art-poll-text-d {
  font-size: 18px;
  font-family: Raleway, sans-serif !important;
  font-weight: 600;
  color: #FF942C;
}

.question_post {
  font-weight: bold;
  font-family: Raleway, sans-serif;
  font-size: 16px;
}

/* Container for the menu icon and the sliding bar */
.menublue-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  z-index: 1;
}

/* Menu Icon Circle */
.menublue-icon {
  width: 35px;
  height: 35px;
  box-shadow: inset 0px 0px 0px 2px #ffffff;
  border-radius: 50%;
  background-color: #31197C;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  transition: transform 0.3s ease-in-out;
}

/* .menublue-icon .icon {
    width: 20px;
    height: 2px;
    background-color: white;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    color:white;
  } */

/* When the menu is open, rotate the icon */
.memenubluenu-icon.open .icon {
  transform: rotate(45deg);
}

.ic-top {
  margin-top: -3px;
}

/* Sliding Menu Bar */
.menublue-bar {
  position: absolute;
  top: 49%;
  right: 30px;
  width: 0;
  height: 35px;
  background-color: #31197C;
  border-radius: 40px;
  overflow: hidden;
  transform: translateY(-49%);
  transition: width 0.3s ease;
  z-index: -1;
}

/* Show the bar when the menu is open */
.menublue-bar.open {
  width: 200px;
}

/* Menu Items inside the bar */
.menublue-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.menublue-item {
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;
  margin-top: -5px;
  color: white;
}

.menublue-item:hover {
  color: #ffffff;
}

.tshirt-img {
  width: 90%;
  height: 90%;
  margin-right: 3px;
  margin-left: 10px;
}

.tshirt-img1 {
  width: 90%;
  height: 90%;
  margin-right: 10px;
  margin-left: 3px;
}

.tshirt {
  font-size: 18px;
  font-weight: 600;
  font-family: Noto Sans;
}

.tshirt-left {
  padding-left: 0.5rem;
}

.ts-left-right-d {
  margin-left: 1rem;
  margin-right: 1rem;
}