.topics-select-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
}

.dropdown-container-home {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dropdown-home {
    flex: 1;
    margin: 0 5px;
    display: flex;
    align-items: center;
    /* Align items vertically in the center */
}


.active-events {
    background: #ff942c;
    color: white;
    border-radius: 20px;
    display: flex;
    padding: 0 2px;
    justify-content: space-between;
    align-items: center;
    border: 2px solid transparent;
}

.active-events-content {
    display: flex;
    align-items: center;
    margin-top: -1px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    font-family: Noto Sans;
}

.event-count {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    padding: 1px 2px 2px 2px;
    margin-top: -1px;
    align-items: center;
    justify-content: center;
    background: #EF9263;
    text-align: center;
    font-size: 14px;
    font-family: Noto Sans;
    color: white;
}

.uppercase-bold-home {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    font-family: Noto Sans;
}

/* .s-home {
    width: 100%;
    border: 2px solid transparent; 
    border-radius: 20px;
    padding: 5px;
    font-size: 13px;
    cursor: pointer;
    outline: none;  
} */
/* .s-home {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #31197C;
    background-image: url('../../src/images/dropdown.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 15px;
    padding: 5px 10px 5px 5px;
    border: 2px solid transparent;
    border-radius: 20px;
    color: white;
    font-size: 13px;
    outline: none;
    cursor: pointer;
    width:100%;
} */
.s-home {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #31197C;
    background-image: url('../../src/images/dropdown.svg'); 
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 14px;
    padding: 5px 10px 5px 5px;
    border: 2px solid transparent;
    border-radius: 20px;
    color: white;
    font-size: 13px;
    outline: none;
    cursor: pointer;
    width: 100%;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.s-home.select1 {
    border: 2px solid #ff942c;
}

.active-events.select2 {
    border: 2px solid #31197C;
}
