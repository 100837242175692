.payment-details-container {
    max-width: 600px;
    margin: 0 20px;
    font-family:Noto Sans;
}

.payment-methods {
    display: flex;
    justify-content: center;
}

.payment-methods div {
    padding: 5px 10px;
    border: none;
    width: 8rem;
    font-weight: 600;
    text-align: center;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 4px;
}

.payment-methods div.active {
    background-color: #FF942C;
    color: white;
}

.payment-form input {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.payment-submit-button {
    position: absolute;
    bottom: 4.5rem;
    right: 20px;
    margin: 0 auto;
    padding: 5px 20px;
    border: none;
    width:89%;
    font-family:Noto Sans;
    font-weight: 600;
    background-color: #31197C;
    color: #fff;
    cursor: pointer;
}

.payment-submit-button:hover {
    background-color: #31197C;
    color: #fff;
}
.payment-back-button {
    position: absolute;
    bottom: 4.5rem;
    left: 20px;
    margin: 0 auto;
    padding: 5px 20px;
    border: none;
    font-family:Noto Sans;
    width:6rem;
    font-weight: 600;
    background-color: #31197C;
    color: #fff;
    cursor: pointer;
}

.payment-back-button:hover {
    background-color: #31197C;
    color: #fff;
}