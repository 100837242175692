.settings {
    margin-top: 28%;
    margin-left: 1.6rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #1E2022;
}

.settings-new {
    margin-top: 10%;
    margin-left: 1.6rem;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0.8px;
    color: #FF942C;
}

.settings-text {
    margin-top: 3px;
    padding: 8px 8px 8px 32px;
    text-decoration: none !important;
    font-size: 20px;
    font-weight: 500;
    color: black;
    display: block;
}

.settings-text:hover {
    color: black;
}

.notification-icon {
    width: 30px;
    height: 30px;
}

.notification-icon1 {
    width: 30px;
    height: 30px;
}

.change-card {
    width: 140px;
    height: 140px;
    display: flex;
    padding: 0 6px 6px 6px;
    margin: 10px 0.6rem 10px 1.8rem;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.close-int {
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 26px;
    color: #AAAAAA !important;
}

.change-card-left {
    margin-left: 0.4rem;
}

.change-text {
    margin-top: 10px;
    font-size: 14px;
    color: black;
}