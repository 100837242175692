.event-card {
    background: linear-gradient(to bottom, #f7e3d7, #fcf2f0,#ebe7f5,#eef2fb);
    border-radius: 40px 40px 0 0;
    padding-top: 5px;
    margin:2.5rem 4% 0 4%;
    width: 92%;
    height:82vh;
    /* box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.3); */
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3); 
    text-align: center;
  }
  .event-circle{
    width:30px;
    height:30px;
    border-radius: 50%;
    background: white;
    border:2px solid lightgray;
    /* box-shadow: -4px 3px 2px 2px rgba(168, 168, 168, 0.5) inset; */
    box-shadow: inset -3px 3px 5px rgba(100, 100, 100, 0.7); 
  }
  .event-header {
    margin-bottom: 10px;
  }
  
  .event-header h2 {
    font-size: 24px;
    margin: 0;
  }
  
  .event-header p {
    margin: 5px 0;
  }
  
  .event-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .event-host {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .host-image {
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .event-about {
    text-align: left;
  }