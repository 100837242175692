.bottom_bar {
  z-index: 1200;
  background-color: white;
  position: fixed;
  bottom: -1px;
  width: 100%;
  height: 61px;
  border-top: 1px solid rgba(48, 25, 124, 0.3);
  pointer-events: auto;
}

.Active {
  border-top: 4px solid #31197C;
  width: 70px;
  margin-top: -5px;
}

/* .active_nav{
  border-top: 5px solid #31197C;
  width:70px;
} */
.menu-Icon {
  width: 22px;
  height: 23px;
}

.menu-Icon1 {
  width: 28px;
  height: 29px;
}

/* .menu-icon{
  width: 22px;
  height:23px;
  margin:10px 4px 3px 25px;
} */
/* .menu-text{
color:black;
font-size:13px;
} */
.menu-text-color {
  color: black;
  font-family: Noto Sans;
  font-size: 11px;
  font-weight: 600;
}

.menu-text {
  margin-left: 15px;
  color: black;
  font-size: 13px;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1300;
  top: -4px;
  left: 0;
  background-color: #f9f9f9;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav-text {
  padding: 8px 8px 8px 32px;
  text-decoration: none !important;
  font-size: 16px;
  color: #3D3D3D;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: black;
}

.sidenav .closebtn {
  position: absolute;
  top: 0.2%;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* .closebtn {
  position: absolute;
  bottom: 10;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
} */
.user-img {
  width: 110px;
  margin-top: -2.7rem;
}

.user-name {
  margin-top: 0.2rem;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #3D3D3D;
}

.user-id {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #3D3D3D;
}

.usericon {
  width: 25px;
  height: 25px;
}

.favicon {
  width: 20px;
  height: 20px;
}

.pollicon {
  width: 27px;
  height: 28px;
}

.pricingicon {
  width: 30px;
  height: 27px;
}

.logouticon {
  width: 23px;
  height: 23px;
}

.logouticon1 {
  width: 50px;
  height: 50px;
}

.hr-width {
  width: 80%;
  margin-top: 1px;
  margin-bottom: 1px;
  align-items: center;
  justify-content: center;
}

.downarrow {
  width: 10px;
  height: 10px;
  float: right;
  margin-right: 1.9rem;
  margin-top: 6px;
}

.toggle-text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3D3D3D;
  padding: 8px 8px 8px 36px;
  text-decoration: none;
  display: block;
}

.toggle-text:hover {
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
  color: black;
}

.line_image {
  width: 6.5%;
  margin-top: -4px;
  margin-left: 2.65rem;
}

.user_profile_alignment {
  margin-top: -7.4rem;
}

.profile-text-user {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 10px 2px 10px 64px;
  color: #3D3D3D;
  text-decoration: none;
}

.profile-text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 2px 2px 2px 64px;
  color: #3D3D3D;
  text-decoration: none;
}

.profile-edit {
  font-size: 10px;
  float: right;
  margin-top: -1.7rem;
  margin-right: 1.9rem;
  color: black;
}

.profile-color {
  color: #31197C;
  width: 30px;
  height: 8px;
}

.scm-text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #3D3D3D;
  padding: 1px 1px 1px 75px;
  text-decoration: none;
}

.scm-text:hover {
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: black;
}

.cancelbtn,
.deletebtn {
  float: left;
  width: 50%;
  margin-top: -3px;
  margin-bottom: 2px;
}

.cancelbtn {
  cursor: pointer;
}

.deletebtn {
  cursor: pointer;
}

._container {
  padding: 15px;
  text-align: center;
}

._container-howtoplay {
  padding: 15px;
  text-align: left;
}

.modal-pop {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: -4px;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: white;
  border: 0.2px solid #D3D3D3;
  border-radius: 18px;
  margin: auto;
  font-size: 15px;
  /* width: 52%; */
}

.modal-content-log {
  background-color: white;
  border: 0.2px solid #D3D3D3;
  border-radius: 18px;
  margin: auto;
  font-size: 15px;
  width: 65%;
  z-index: 20;
}

.modal-content-log-height {
  background-color: white;
  border: 0.2px solid #D3D3D3;
  border-radius: 18px;
  margin: auto;
  font-size: 15px;
  width: 65%;
  z-index: 20;
}

.modal-content-log-purchase {
  background-color: white;
  border: 0.2px solid #D3D3D3;
  border-radius: 18px;
  margin: auto;
  font-size: 14px;
  width: 80%;
}

.cancelbutton,
.deletebutton {
  width: 5rem;
  height: 33px;
  color: #31197C;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #31197C;
  padding-bottom: 5px;
  padding: 5px;
  border-radius: 10px;
}

.cancelbutton:hover,
.deletebutton:hover {
  width: 5rem;
  height: 33px;
  font-weight: 600;
  font-size: 14px;
  color: white;
  border: 1px solid #31197C;
  background-color: #31197C;
  padding-bottom: 5px;
  padding: 5px;
  border-radius: 10px;
}

.modal-post {
  display: none;
  position: fixed;
  overflow-y: hidden;
  z-index: 151;
  left: 0;
  top: -4px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content-log-post {
  background-color: white;
  border: 0.2px solid #D3D3D3;
  border-radius: 18px;
  margin: auto;
  font-size: 15px;
  width: 90%;
  z-index: 151;
}

/* body.modal-open {
  overflow: hidden;
}
body.modal-open .modal-post {
  display: flex;
} */
.cancelbutton {
  cursor: pointer;
}

.deletebutton {
  cursor: pointer;
}

._password {
  width: 94px;
  height: 18px;
  font-size: 10px;
  margin-left: 4rem;
  color: #3D3D3D;
}

/* .done-btn{
      width:40px;
      height:14px;
      font-size: 10px;
      font-weight:600;
      background-color: #FF942C;
      color:white;
      padding-bottom:2px;
    } */
.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.not-disabled {
  opacity: 1;
}

.done-btn {
  width: 40px;
  height: 16px;
  color: white;
  font-size: 9.3px;
  padding: 0 1px 3px 1px;
  background: #FF942C;
  margin-top: -1.2rem;
  float: right;
  margin-right: 4rem;
}

.done-btn:hover {
  width: 40px;
  height: 16px;
  color: white;
}

.element {
  margin-top: -0.5px;
  /* background: linear-gradient(-45deg, rgba(0,0,0,0.22), rgba(255,255,255,0.25)); */
  border-radius: 10%;
  justify-content: center;
  width: 49px;
  height: 49px;
  /* box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
  -3px -3px 6px 0 rgba(255, 255, 255, 0.3); */
}

.element-3 {
  margin-top: -0.5px;
  box-shadow: inset 2px 2px 3px 0 rgba(0, 0, 0, 0.25),
    inset -3px -3px 3px 0 rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  /* border:1px solid #31197C; */
  justify-content: center;
  width: 49px;
  height: 49px;
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
}

.reviewer-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: -4px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}

.reviewer-modal-content-log {
  background-color: white;
  border: 0.2px solid #D3D3D3;
  border-radius: 18px;
  margin: auto;
  font-size: 15px;
  width: 58%;
}

.reviewer_container {
  padding: 10px;
  text-align: center;
}

.tick-color-green {
  color: rgb(6, 155, 6);
}

.tick-color-amber {
  color: #FFBF00;
}

.tick-color-red {
  color: red;
}

#false {
  color: red;
}

#true {
  color: rgb(6, 155, 6);
}

#rejectedSme {
  color: red;
}

#rejectedAuthor {
  color: red;
}

#rejectedReviewer {
  color: red;
}

#approvedSme {
  color: rgb(6, 155, 6);
}

#approvedAuthor {
  color: rgb(6, 155, 6);
}

#approvedReviewer {
  color: rgb(6, 155, 6);
}

#pendingAuthor {
  color: #FFBF00
}

#pendingReviewer {
  color: #FFBF00
}

#pendingSme {
  color: #FFBF00
}

.disabled-link {
  pointer-events: none;
  opacity: 0.6;
}

.coin-an {
  position: relative;
  animation: ex 2s linear;
}

@keyframes ex {
  0% {
    right: -200px;
  }

  100% {
    right: 0px;
  }
}

@keyframes spin-vertical {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.coin-rotate {
  animation: spin-vertical 3s linear;
}

.swing {
  animation: swing 1.5s ease-in-out 1; 
  transform-origin: bottom center;
}

/* @keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
} */
@keyframes swing {
  0% {
    transform: rotate(15deg);   /* Start at +15 degrees */
  }
  25% {
    transform: rotate(-10deg);  /* Swing to -10 degrees */
  }
  50% {
    transform: rotate(10deg);   /* Swing to +10 degrees */
  }
  75% {
    transform: rotate(-5deg);    /* Return to -5 degrees */
  }
  100% {
    transform: rotate(0deg);     /* End back at 0 degrees */
  }
}