.poll-main {
  width: 100%;
  height: 200px;
}

.poll-latest-news {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.4px;
  color: #1E2022;
  margin-left: 1.6rem;
  margin-top: -20px;
}

.scroll-polls {
  width: 150px;
  height: 200px;
  display: flex;
  padding: 0 6px 6px 6px;
  margin-right: 10px;
  margin-top: -10px;
}

.poll-img {
  width: 150px;
  height: 200px;
  border-radius: 10px;
}

.poll-heading {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  color: #FFFFFF;
  margin-top: -2.3rem;
  margin-left: 0.6rem;
}

.poll-btn {
  width: 9rem;
  height: 40px;
  color: white;
  border-radius: 4px;
  background: #FF942C;
}

.poll-btn:hover {
  color: white;
  background: #FF942C;
}

.search-box-container input {
  padding: 5px 20px 5px 5px;
  width: 10rem;
  height: 40px;
  border-radius: 4px;
  border: 1px solid grey;
}

.search-box-container:after {
  content: "\f002";
  font-family: FontAwesome;
  margin-left: -25px;
}

.history-card {
  margin-top: 1rem;
  width: 100%;
  height: 14vw;
  display: flex;
  padding: 12px 6px 6px 10px;
  margin-right: 10px;
  border-radius: 10px;
}

.popular-card {
  width: 100%;
  height: 14vw;
  display: flex;
  padding: 6px;
  border-radius: 10px;
}

.currentpoll-Text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin: 8px auto 8px auto;
  color: #FF942C;
}

.poll-active {
  color: #FF942C !important;
  border-bottom: 2px solid #FF942C;
}

.question-active {
  color: #FF942C !important;
  border-bottom: 2px solid #FF942C;
}

.poll-rec-left {
  margin-left: 20%;
}

.current-poll-fixed {
  background-color: white;
  position: fixed;
  width: 100%;
  top: 3rem;
  margin-top: 20px;
  height: 55px;
  z-index: 100;
}

.ques-size {
  font-size: 14px;
}

.date-font-size {
  font-size: 11.5px;
}

.percent {
  color: #31197C;
  font-size: 20px;
  font-weight: 600;
  float: right;
  justify-content: right;
  align-items: flex-end;
  margin-top: -1.55rem;
}

.poll-bg {
  background-color: #31197C;
  height: calc(95vh);
  overflow-y: auto;
}

.poll-text {
  font-size: 15px;
  font-family: Noto Sans;
  align-items: flex-start;
  float: left;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 50%;
  }

  to {
    margin-left: 0%;
  }
}

._vl {
  border-left: 1px solid grey;
  height: 100vh;
  position: absolute;
  left: 33.33%;
  top: 23.2%;
  opacity: 0.4;
  width: 0.6px;
  background-color: grey;
}

._vl-1 {
  border-left: 1px solid grey;
  width: 0.6px;
  background-color: grey;
  height: 100vh;
  position: absolute;
  left: 66.66%;
  top: 23.2%;
  opacity: 0.4;
}

.procurementg {
  width: 48px;
  height: 49px;
}

.topblue_bar {
  z-index: 1;
  overflow: hidden;
  background-color: #31197C;
  position: fixed;
  top: -5px;
  width: 100%;
  height: 70px;
}
.poll-answer-card-post {
  align-items: center;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  height: auto;
  width: 94%;
  background-color: #ebfcfc;
  border-radius: 20px;
  margin: 15px auto;
}
.poll-answer-card-post-p {
  align-items: center;
  padding: 0.5rem 0.5rem 0.8rem 0.5rem;
  height: auto;
  width: 100%;
  background-color: #ebfcfc;
  border-radius: 20px;
  margin: 8px auto;
}
.poll-answer-card {
  align-items: center;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  height: auto;
  width: 94%;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto;
}

.poll-card {
  align-items: center;
  padding: 1rem 1rem 1.5rem 1rem;
  height: auto;
  width: 320px;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto 0 auto;
}

.topic-top {
  margin-top: 2vh;
}

.topic {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.8px;
  color: #FFFFFF;
}

.topic-name {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: 0.8px;
  color: #FF942C;
}

.poll-question {
  padding: 0.5rem;
  margin-top: 0.2rem;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.8px;
  color: #000000;
}
.poll-question-p {
  padding: 0.4rem;
  margin-top: 0.2rem;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.8px;
  color: #000000;
}
.polloptions-card {
  margin-top: 1rem;
  width: 98%;
  height: 70px;
  display: flex;
  padding: 10px 6px 10px 6px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}
.polloptions-card-post {
  margin-top: 0.6rem;
  width: 98%;
  height: 7vh;
  display: flex;
  padding:1px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: white;
  border: 1px solid lightgrey;
}
.poll-submit-btn {
  width: 8rem;
  height: 50px;
  color: black;
  border-radius: 6px;
  background: white;
}

.poll-submit-btn:hover {
  color: black;
  background: white;
}
.q-submit-btn {
  width: 4.5rem;
  height: 32px;
  color: white;
  padding-top: -5px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Noto Sans;
  background: #31197C;
}

.q-submit-btn:hover {
  color: white;
  background: #31197C;
}
.goback-poll-btn {
  width: 10rem;
  height: 50px;
  color: black;
  border-radius: 6px;
  background: white;
}

.goback-poll-btn:hover {
  color: black;
  background: white;
}
.poll-share-icon{
  float: right;
  color:white;
  font-size: 25px;
}
.selected {
  background: #FFE6CE !important;
  border: 1px solid #DB6C00;
}

.isSelected {
  border: 2px solid #DB6C00;
}

.answers-card {
  margin-top: 10vh;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.float-count {
  float: right;
  margin-right: 10px;
}

.options {
  padding: 5px;
  border: 1px solid #000;
  cursor: pointer;
  width: 50%;
  background-color: white;
  color: rgb(12, 12, 12);
  font-weight: bold;
}

.answers {
  list-style-type: none;
  margin: 5px;
}

.transition {
  transition: 2s ease-in-out;
  -webkit-transition: 2s ease-in-out;
}

/* .containerStyles{
    height: 50px;
    width: 80%;
    background-color: white;
    border-radius: 10px;
    margin: 8px;
    border: 1px solid lightgrey;
} */
.labelStyles {
  color: black;
  font-weight: 600;
  padding: auto;
}

#_Search {
  background-image: url('../../src/images/searchwhite.png');
  background-position: 96% 50%;
  background-size: 12%;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 100;
  font-size: 16px;
  padding: 12px 35px 12px 12px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
}

._Search-border {
  border: 1.5px solid #E3E3E3 !important;
  border-radius: 4px;
  height: 40px;
}

/* .search{
    width:9rem;
    height:40px;
    border-radius: 2px;
}
.search-icon{
    margin-left:-1.8rem;
    margin-top:0.8rem;
    z-index:1;
    width:20%;
} */
.idx {
  color: #FF942C;
  font-weight: 500;
  font-size: 30px;
  position: relative;
  top: -2rem;
  left: 2rem;
  /* margin-top:-6rem;
  margin-left:4.6rem; */
}

/* .poll-ques-size{
  font-size:14px;
  position:relative;
  top:-2.3rem;
} */
.poll-ques-size {
  width: 200px;
  font-size: 16px;
  position: relative;
  top: -2.3rem;
}

.days-img {
  width: 70px;
}

.days-left {
  position: absolute;
  font-size: 11px;
  right: 0.8rem;
  top: -0.3rem;
  float: right;
  font-weight: 600;
}

.poll-topic-imgs {
  position: relative;
  width: 25px;
  top: -2rem;
  right: 2px;
  float: right;

}

/* .poll-ques-size{
  font-size:20px;
  position:relative;
  top:-7.5rem;
  left:6rem; 
} */
.pg-bar {
  position: relative;
  top: 5.1rem;
  z-index: -1;
  background: #31197C;
}

/* .progress-bar {
  background-color: #d4d2d8!important;
  background:#31197C;
} */
.bg-Bar {
  background-color: #d4d2d8 !important;
}

.-container_ {
  display: grid;
  grid-template-columns: 80px 160px 60px;
  grid-gap: 10px;
  background-color: white;
}

.current-poll-circle {
  width: 65px;
  height: 40px;
  border-right: 2px solid #31197C;
  border-top: 2px solid #31197C;
  border-bottom: none !important;
  border-left: 2px solid #31197C;
  border-radius: 65px 65px 0 0;
  margin-left: 2px;
  transform: rotate(270deg);
}

.current-poll-rectangle {
  height: 65.5px;
  border: 1px solid #31197C;
  margin-left: 4%;
  margin-top: -15px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: none;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.history-poll-circle {
  width: 65px;
  height: 40px;
  border-right: 2px solid #31197C;
  border-top: 2px solid #31197C;
  border-bottom: none !important;
  border-left: 2px solid #31197C;
  border-radius: 65px 65px 0 0;
  margin-left: 2px;
  transform: rotate(270deg);
  background: rgb(243, 243, 243);
}

.history-poll-rectangle {
  background-color: #F6F6F6;
  height: 65.5px;
  border: 1px solid #31197C;
  margin-left: 4%;
  margin-top: -15px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: none;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.bottom-history-text {
  position: absolute;
  bottom: 4.2rem;
  margin-left: 25%;
  margin-right: 25%;
  color: red;
  font-size: 14px;
}