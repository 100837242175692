.spinnerloading {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #31197C;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: lspin 1s linear infinite;
    margin: auto;
    margin-top: 40vh;
    display: block;
}

@keyframes lspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.screen-topgap-a {
    margin-top: 6vh;
  }
.circle-container {
    position: relative;
    width: 193px;
    height: 193px;
    background-color: transparent;
    border-radius: 50%;
    border-top: 3px solid #31197C;
    border-left: 3px solid #ff942c;
    border-right: 3px solid #31197C;
    border-bottom: 3px solid #ff942c;
    transform: rotate(-45deg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-container::before,
.circle-container::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #FF942C;
    border-radius: 50%;
}

.circle-container::before {
    top: 10%;
    left: 16%;
    transform: translateX(-50%);
}

.circle-container::after {
    right: 10%;
    top: 85%;
    transform: translateY(-50%);
}

/* .circle-container::before {
    top: 50%;
    left:0%;
    transform: translateX(-50%);
}
.circle-container::after {
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
} */
.bars-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    width: 100px;
    height: 100%;
    transform: rotate(-45deg);
}

.ba {
    width: 30px;
    background-color: #ff942c;
}

.purple-bar-container {
    width: 93%;
    margin: 2px 6%;
    position: relative;
}

.count-top {
    margin-top: 31px;
}

.p-bar-r {
    height: 25px;
    padding: 1px;
    border-radius: 6px;
    background-color: #E7E2FA;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.3);
    margin-left: -13%;
    width: 110%;
    z-index: -1;
}

.c-bar-r {
    height: 25px;
    padding: 1px;
    border-radius: 6px;
    background-color: #FFF3CD;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.3);
    margin-left: -13%;
    z-index: -1;
}

.p-bar-u {
    height: 25px;
    padding: 1px;
    border-radius: 6px;
    background-color: #D9D1F7;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.3);
    margin-left: -13%;
    z-index: -1;
}

.c-bar-u {
    height: 25px;
    padding: 1px;
    border-radius: 6px;
    background-color: #FFE9A3;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.3);
    margin-left: -13%;
    z-index: -1;
}

.text-b-dashboard {
    color: black;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-right: 10px;
    font-weight: 500;
}

.ap-box {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 14px;
    border: 1px solid lightgray;
    /* box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 6px rgba(255, 253, 253, 0.7); */
    box-shadow: 
    inset 3px 3px 6px rgba(240, 240, 240, 0.3),  /* Light off-white shadow from top-left */
    inset -3px -3px 6px rgba(255, 255, 255, 0.6),  /* Light highlight from bottom-right */
    inset 0px 0px 12px rgba(220, 220, 220, 0.4), /* Deeper off-white shadow around the edges */
    inset 0px 0px 25px rgba(240, 240, 240, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.purple-bar {
    position: absolute;
    height: 45px;
    background-color: #E7E2FA;
    border-radius: 30px;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.3);
    z-index: 2;
    width: 50%;
    top: 0;
    left: 0;
    margin-top: -1px;
}

.in-purple-bar {
    position: relative;
    height: 45px;
    background-color: #E7E2FA;
    border-radius: 30px;
    z-index: 1;
    width: 94%;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.3);
}

.text-purple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    color: black;
    text-align: center;
}


.cream-bar-container {
    width: 93%;
    margin: 2px 6%;
    position: relative;
}

.cream-bar {
    position: absolute;
    height: 45px;
    background-color: #FFF3CD;
    border-radius: 30px;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.3);
    z-index: 2;
    width: 50%;
    top: 0;
    left: 0;
    margin-top: -1px;
}

.in-cream-bar {
    position: relative;
    height: 45px;
    background-color: #FFF3CD;
    border-radius: 30px;
    z-index: 1;
    width: 94%;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.3);
}

.r-box {
    width: 100%;
    border-radius: 30px;
    padding: 10px 20px;
    border: 1px solid lightgray;
    background-image:
        radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)),
        linear-gradient(white, #F9F2ED, #F9F2ED, #F9F2ED, white);

    box-shadow:
        0 4px 7px rgba(0, 0, 0, 0.2),
        0 -4px 10px rgba(255, 255, 255, 0.4);
}

.m-box {
    width: 100%;
    border-radius: 30px;
    padding: 10px 20px;
    border: 1px solid lightgray;
    background-image:
        radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)),
        linear-gradient(white, #ECF9E7, #ECF9E7, #ECF9E7, white);

    box-shadow:
        0 4px 7px rgba(0, 0, 0, 0.2),
        0 -4px 10px rgba(255, 255, 255, 0.4);
}

.e-box {
    width: 100%;
    border-radius: 30px;
    padding: 10px 20px;
    border: 1px solid lightgray;
    background-image:
        radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)),
        linear-gradient(white, #DDE8FF, #DDE8FF, #DDE8FF, white);

    box-shadow:
        0 4px 7px rgba(0, 0, 0, 0.2),
        0 -4px 10px rgba(255, 255, 255, 0.4);
}

.toggle-container-admin {
    width: 60px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    /* To ensure positioning of the inner circle */
}

.toggle-circle-admin {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    top: 0;
    left: 3px;
    transition: transform 0.3s;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.toggle-on-adm {
    background-color: green;
    justify-content: flex-end;
}

.toggle-off-adm {
    background-color: red;
    justify-content: flex-start;
}

.toggle-on-adm .toggle-circle-admin {
    transform: translateX(30px);
    /* Move the circle to the right when ON */
}

.bg-merchandise {
    margin-top: -5px;
    padding: 4px 7px 7px 7px;
    background: #F1F4F8;
    font-family: Noto Sans;
}

.bg-white {
    padding: 4px;
    background: white;
    border-radius: 8px;
    font-family: Noto Sans;
}

.bg-eve {
    padding: 4px;
    background: #F1F4F8;
    border-radius: 8px;
    font-family: Noto Sans;
}

.adm-bg-height {
    height: calc(68vh);
    overflow-y: auto;
}

.adm-bg-height-eve {
    height: calc(73vh);
    overflow-y: auto;
}

.adm-order-box {
    font-size: 12px;
    font-family: Noto Sans;
    border: 1px solid lightgray;
    padding: 2px 3px;
    border-radius: 5px;
    color: black;
}

.view_eve_card {
    height: auto;
    width: 100%;
    margin-bottom: -10px;
    background: #f1f4f8;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;
}

.bar-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 5px 50px 5px;
}

.bar-chart {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;
    max-width: 120px;
    height: 150px;
}

.bars {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 45px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    transition: transform 0.3s ease-in-out;
    margin-right: 5px;
}

/* Remove margin for the last bar to avoid unnecessary space */
.bars:last-child {
    margin-right: 0;
}

.bars:hover {
    transform: scale(1.1);
    /* Scale up on hover */
}

.bar-label {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    color: #555;
}

.bars::after {
    content: attr(data-count);
    font-size: 14px;
    position: absolute;
    top: -20px;
    color: #fff;
    font-weight: bold;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    /* Place tooltip above the bar */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.bar-label {
    font-weight: bold;
}

.bar-count {
    margin-left: 5px;
}
.art-card{
    height: auto;
    width: 100%;
    padding:5px;
    font-size: 12px;
    background: #f1f4f8;
    border-radius: 7px;
    border: none;
    box-shadow: 2px 2px 6px #bebebe,
        -2px -2px 6px #ffffff;   
}