.Article_Image_width{
    width:100%;
    height:520px;
    margin-top:-3px;
}
._heading{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
    color: #FFFFFF;
    position:absolute;
    top:80%;
    margin-left:1.6rem;
    }
    .tagline{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: black;
    margin-left:1.8rem;
    }
    .author-icon{
        width:40px;
        margin-left:2.5rem;
        margin-top:-10px;
        }
    .authorName-d{
        margin-left:2.5rem;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: black;
        }
    .pubDate-d{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: black;
    margin-top:5px;
    margin-left:1.7rem;
    }
    .html-text-new{
        font-family: Noto Sans;
        font-style: normal;
        font-size: 13px;
        color: #000000;
        margin-left:1.8rem;
        margin-right:1rem;
        margin-top:0.2rem;
        }
        .g-play{
         width:15%;
        }
        /* .sceem-white-logo{
            position:relative;
            top:10%;
width:5%;
margin-left:2rem;
z-index:10;
        } */
        /* .sceem-white-logo{
            position: absolute;
            margin-top:0.5rem;
            left:4%;
            width:6%;
    }
        .powered_by{
            position: absolute;
            margin-top:20rem;
            left:88%;
            width:10%;
        }
        .app_background{
            width:100%;
            height:auto;
        }
        .app_border{
            position:absolute;
            left:10%;
            right:10%;
            align-items: center;
            justify-content: center;
            margin-top:-23%;
            border:2px solid white;
            border-radius:15px;
            width:80%;
            height:30%;
        } */
        /* .app_background{
            width:100%;
            height:auto;
        }
        .app_border{
            position:absolute;
            left:10%;
            right:10%;
            align-items: center;
            justify-content: center;
            margin-top:-35%;
            border:2px solid white;
            border-radius:15px;
            width:80%;
            height:38%;
        }
        .powered-by-nebutech{
            position: relative;
            margin-top:-26rem;
            left:3%;
            width:13%;
        }
        .sceem-logo-download{
            position: relative;
            width:8%;
            margin-top:-35rem;
            margin-left:2rem;
        }
        .download-text{
            margin-top:-1.5rem;
            font-size:25px;
            color:white;
            font-weight:600;
            font-family: Open Sans;
        }
        .download-text-sm{
            margin-top:0.5rem;
            font-size:15px;
            color:white;
            font-weight:400;
            font-family: Open Sans;
        }
        .googleplay{
            width:20%;
        } */
        
.app-background{
    width:100%;
    height:auto;
}
.app-border{
    position:absolute;
    left:10%;
    right:10%;
    align-items: center;
    justify-content: center;
    margin-top:-26%;
    border:2px solid white;
    border-radius:15px;
    width:80%;
    height:38%;
}
.powered-by{
    position: relative;
    margin-top:-7rem;
    left:88%;
    width:10%;
}
.powered-by-nebutech{
    position: relative;
    margin-top:4rem;
    left:1%;
    width:15%;
}
.sceem-logo-download{
    width:8%;
    margin-top:0.6rem;
    margin-left:0.6rem;
}
.download-text{
    margin-top:-9.5rem;
    font-size:25px;
    color:white;
    font-weight:600;
    font-family: "Open Sans", sans-serif;
}
.download-text-sm{
    margin-top:0.5rem;
    font-size:15px;
    color:white;
    font-weight:400;
    font-family: "Open Sans", sans-serif;
}
.googleplay{
    width:20%;
}
@media screen and (max-width:960px){
    .Article_Image_width{
        width:100%;
        height:350px;
    }
    ._heading{
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 25px;
        line-height: 34px;
        color: #FFFFFF;
        position:absolute;
        top:36.5%;
        margin-left:1.6rem;
        }
        .tagline{
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: black;
        margin-left:1.7rem;
        }
        .author-icon{
            width:40px;
            margin-left:2.5rem;
            }
        .authorName{
            margin-left:0.5rem;
            font-family: Noto Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: black;
            }
        .pubDate{
        font-family: Noto Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: black;
        margin-left:0.5rem;
        }
        .html-text-new{
            font-family: Noto Sans;
            font-style: normal;
            font-size: 11px;
            color: #000000;
            margin-left:1.8rem;
            margin-right:1rem;
            margin-top:0.2rem;
            }
.g-play{
 width:20%;
 margin-top:1rem;
 margin-bottom: 0.5rem;
 }
 .app-background{
    width:100%;
    height:auto;
}
.app-border{
    position:absolute;
    left:10%;
    right:10%;
    align-items: center;
    justify-content: center;
    margin-top:-32.8%;
    border:2px solid white;
    border-radius:15px;
    width:80%;
    height:15.5%;
}
.powered-by{
    position: relative;
    margin-top:-2.8rem;
    left:86%;
    width:12%;
}
.download-text{
    margin-top:-5.8rem;
    font-size:18px;
    color:white;
    font-weight:600;
    font-family: Open Sans;
}
.download-text-sm{
    margin-top:0.2rem;
    font-size:12px;
    color:white;
    font-weight:400;
    font-family: Open Sans;
}
.googleplay{
    width:23%;
}
.powered-by-nebutech{
    position: relative;
    margin-top:2.5rem;
    left:2%;
    width:18%;
}
.sceem-logo-download{
    width:10%;
    margin-top:0.4rem;
    margin-left:0.4rem;
}
}